.main{
	&--slider{
		background: #fff;
		overflow: hidden;
		padding: 0 0 40px 0;
		&--inner{
			max-width: 1366px;
			margin: 0 auto;
			width: 100%;
			
		}
		&--arrow{
			&--left{
				background: url('../images/left-slider-arrow.png') no-repeat;
				width: 54px;
				height: 106px;
				left: 0px;
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
				cursor: pointer;
				display: none;
				
				@media all and (min-width:1024px){
					//left: -80px;
					left: 10px;
					display: block;
				}
				
			}
			&--right{
				background: url('../images/right-slider-arrow.png') no-repeat;
				width: 54px;
				height: 106px;
				right: 0px;
				position: absolute;
				top: 0;
				margin: auto;
				bottom: 0;
				cursor: pointer;
				display: none;
				@media all and (min-width:1024px){
					//right: -80px;
					right: 10px;
					display: block;
				
				}
			}
		}
	}
}
#slider{
	position: relative;
	padding: 50px 0% 0 0%;
	margin: 0 auto;
	width: 100%;
	overflow: hidden;
	
	@media all and (min-width: 1024px){
		//padding: 49px 0 0;
		padding: 49px 7% 0 7%;
		width: 100%;
		float: left;
		overflow: initial;
	}
	> div{
		float: left;
		
	}
	.first-elem-box{
		opacity: 1;
		transition: opacity 0.25s;
	}
	.hide-slide{
		.first-elem-box{
			opacity: 0;
		}
	}
	#first-elem-slider{
		width: 100%;
		border: 4px solid $grey;
		padding: 2px;
		@media all and (min-width: 1024px){
			width: 50%;
			//padding: 46px;
			padding: 2%;
		
		}
		.first-elem-box{
			transition-delay: 0;
		}
		.content{
			float: right;
			width: 100%;
			padding: 5%;
			text-align: center;
			@media all and (min-width: 1024px){
				text-align: left;
				width: 50%;
				padding: 0 0 0 3%;
			}
			p{
				color: $text-grey;
			}
			a{
				color: $dark-grey;
				margin: 10px 0 0 0;
				display: block;
				text-align: right;
				&:hover{
					color:$light-green;
				}
			}
		}
		.image{
			float: left;
			width: 100%;
			text-align: center;
			img{
				width: 100%;
				max-width: 238px;
			}
			@media all and (min-width: 1024px){
				text-align: left;
				width: 50%;
			
			}
		}
	}
	#second-elem-slider{
		width: 25%;
		text-align: center;
		padding: 49px 0;
		border-right: 1px solid #ebebeb;
		display: none;
		@media all and (min-width: 1024px){
			display: block;
		}
		
		.first-elem-box{
			transition-delay: 0.1s;
		}
		img{
			max-width: 100%;
			width: 80%;
		}
	
	}
	#last-elem-slider{
		text-align: center;
		width: 25%;
		padding: 49px 0;
		display: none;
		@media all and (min-width: 1024px){
			display: block;
		}
		.first-elem-box{
			transition-delay: 0.2s;
		}
		img{
			max-width: 100%;
			width: 80%;
		}
	}
}
