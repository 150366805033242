.header{
	background: $light-grey;
	overflow: hidden;
	padding: 33px 0 0 0;
	overflow: visible;
	&--inner{
		max-width: 1366px;
		margin: 0 auto;
		width: 100%;
		padding: 0 5%;
		&:before, &:after{
			content: "";
			clear: both;
			display: block;
		}
		@media all and (min-width: 1024px){
			padding: 0 45px;
		
		}
		
		&--logo{
			float: left;
			position: relative;
			max-width: 50%;
			img{
				width: 100%;
			}
		}
		&--nav{
			float: right;
			transition: all 0.5s;
			height: 0px;
			> div:nth-child(2){
				display: none;
				&:before, &:after{
					clear: both;
					content: '';
					display: block;
				}
				@media all and (min-width: 1024px){
					//height: 80px;
					display: block;
				}
			}
			nav{
				overflow: hidden;
				
				li{
					float: left;
				}
				&#block-bpc-account-menu{
					margin: 20px auto 50px auto;
					@media all and (min-width: 1024px){
						margin: -4px 0 30px 0;
						float: right;
					}
					a{
						color:$light-green;
						background: url('../images/login-ico.png') no-repeat;
						padding: 7px 0 0px 26px;
						line-height: 1.4;
						background-position: 0 50%;
						font-size: map-get($fontSizeMap, minXl);
						height: 29px;
						display: block;
						margin: 0 0 0 32px;
					}
					
				}
				&#block-bpc-main-menu{
					float:right;
					clear:both;
					//height: 100%;
					overflow: visible;
					ul{
						&:before, &:after{
							clear: both;
							content: '';
							display: block;
						}
					}
					li{
					
						//border-right: 1px solid $grey;
						margin: 0;
						padding: 0 1.2vw;
						position: relative;
						&:hover{
							padding: 0 1.2vw 47px;
							background: $light-grey;
							> a{
								color: $grey;
							}
						}
						&:after{
							@media all and (min-width: 1024px){
								content: '';
								display: inline-block;
								width: 1px;
								height: 20px;
								background: $grey;
								position: absolute;
								right: 0;
								top: 0;
							
							}
						}
						&:last-child{
							margin-right: 0;
							padding-right:0;
							&:after{
								content: '';
								display: none;
							}
						}
						ul{
							padding: 0;
							max-width: 90%;
							margin: 30px auto 50px;
							@media all and (min-width: 1024px){
								padding: 30px;
								display: none;
								position: absolute;
								top: 50px;
								right: 0;
								background: #fff;
								border: 1px solid $grey;
								border-top: 5px solid $grey;
								min-width: 250px;
								z-index: 9;
								max-width: 100%;
								margin: 0;
							}
							li{
								border: none;
								padding: 0;
								margin: 0 0 15px 0;
								float: none;
								&:after{
									content: '';
									display: none;
								}
								&:hover{
									background: none;
									padding: 0;
								}
								&:last-child{
									border-bottom: none!important;
								}
								a{
									width: 100%;
									display: block;
								
								}
							}
						}
						&:hover{
							ul{
							
								display: block;
							}
						
						}
					}
					a{
						color: $dark-grey;
						font-weight: bold;
						font-size: map-get($fontSizeMap, small);
						border-bottom: 0px solid $dark-grey;
						transition: all .2s ease 0s;
						
						
					}
				}
			}
		}
		
	}
	&--border{
		height: 6px;
		width: 100%;
		background: $grey;
		margin: 12px 0 0 0;
		&--outer{
			max-width: 1366px;
			margin: 0 auto;
			width: 100%;
			height: 6px;
			overflow: hidden;
			&--inner{
				width: 690px;
				background: $light-grey;
				display: block;
			}
		}
	}
	&.open{
		.header--inner--nav{
			min-height: 100vh;
			width: 100%;
			> div:nth-child(2){
				display: flex;
				flex-direction: column-reverse;
			}
			nav#block-bpc-main-menu{
				width: 100%;
				text-align: center;
				margin: 40px 0 0 0;
				li{
					border-bottom: 1px solid #b5b9aa;
					border-right: none;
					width: 100%;
					margin: 0;
					padding: 10px 0;
				}
			
			} 
		}
	}
}

