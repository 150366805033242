html {
    font-size: 16px;
}

body {
	line-height: 1.3;
	font-family: 'Open Sans', sans-serif;
}

ol,
ul {

    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;

    &:after,
    &:before {
        box-sizing: border-box;
    }
}
a{
	text-decoration: none;
	&:visited{
		color: initial;
	}
}

em {
	font-style: italic;
}

strong {
	font-weight: bold;
}

sup {
	font-size: 0.7em;
	vertical-align: super;
}

sub {
	font-size: 0.7em;
	vertical-align: text-bottom;
}

// PGG: to jest celowa poprawka, akapity tekstu powinny być od siebie oddzielone
p {
	margin-bottom: 1em;
}

figure {
	margin: 1.5em 0;
}

figcaption {
	padding: 0 10px;
	font-size: 0.8em;
}

.visually-hidden{
	display: none;
}
.desktop-view{
	display: none;
	@media all and (min-width: 1024px){
		display: block;
	}
}
.mobile-view{
	display: block;
	@media all and (min-width: 1024px){
		display: none;
	}
}
#block-bpc-content{
	//max-width: 1360px;
    //margin: 30px auto;
}
#block-bpc-local-tasks.contextual-region{
	border-bottom: 1px solid $grey;
    overflow: hidden;
   // top: 30px;
       margin: 30px 0 0 0;
	ul{
		width: 1360px;
		margin: 0 auto;
		li{
			float: left;
			margin: 0 6px 0px 0;
			a{
				border: 1px solid $grey;
				border-bottom: none;
				border-radius: 5px 5px 0 0;
				padding: 5px 11px;
				background: $light-grey;
				display: block;
				color: $dark-grey;
				&.is-active{
					background: #fff;
				}
			}
		}
	}
}
