.unformatted-list{
	margin: 60px 0;
	&.no-margin-top{
		margin: 0 3% 60px 3%;
		@media all and (min-width: 1450px){
			margin: 0 0 60px 0;
		
		}
	}
	&.no-margin-bottom{
		margin: 60px 3% 0 3%;
		@media all and (min-width: 1450px){
			margin: 60px 0 0 0;
		
		}
	}
	&.border-bottom{
		border-bottom: 1px solid #ebebeb;
	}
	&--inner{
		display: flex;
		flex-direction: column-reverse;
		width: 100%;
		@media all and (min-width: 1024px){
			flex-direction: row;
		
		}
		&--left-container{
			width: 100%;
			@media all and (min-width: 1024px){
				width: 62.6%;
			
			}
			&--inner{
				padding: 40px 3% 0 3%;
				@media all and (min-width: 1024px){
					max-width: 871px;
					float: right;
					padding: 40px 119px 0 0;
					min-width: 73%;
				}
				.unformatted-row{
					border-bottom: 1px solid #ebebeb;
					&:last-child{
						border: none;
					}
					.single-element{
						&--inner{
							flex-direction: column;
							padding: 0 3%;
							@media all and (min-width: 486px){
								flex-direction: row;
							}
							&.news{
								flex-direction: column;
								.events{
									margin: 0;
								}
							}
							&.material{
								flex-direction: column;
								h3 a {
									font-size: map-get($fontSizeMap, small);
									@media all and (min-width: 1024px){
										font-size: map-get($fontSizeMap, middleXl);
									
									}
								}
							}
							&.two-sides{
								flex-direction: row;
								padding: 30px 0;
								.single-element--inner--left{
									margin: 0 5% 0 0;
									@media all and (min-width: 1024px){
										margin: 0;
									}
								}
								.single-element--inner--right{
									width: 100%;
								}
							}
						}
					}
				}
			}
			
		}
		&--right-container{
			flex: 1;
			position: relative;
			background: $news-bg;
			
			@media all and (min-width: 1024px){
				
			}
			&--inner{
				width: 100%;
				padding: 3%;
				@media all and (min-width: 1024px){
					width: 449px;;
					float: left;
					padding: 106px 50px 65px 56px;
				
				}
				@media all and (min-width: 1250px){
					padding: 106px 0 65px 56px;
				
				}
			}
			&--title{
				font-size: 4.5vw;
				font-family: 'Noto Serif',serif;
				border-bottom: 5px solid $grey;
				width: 100%;
				display: block;
				padding-bottom: 18px;
				margin: 0 0 35px 0;
				@media all and (min-width: 486px){
					font-size: map-get($fontSizeMap, smallXl);
				
				}
			}
			&--filter-box {
				margin-bottom: 3em;
			}
		}
		&--full-container{
			width: 100%;
			@media all and (min-width: 1024px){
				width: 100%;
				max-width: 1366px;
				margin: 0 auto;
			}
			&--inner{
				padding: 0 3% 0 3%;
				@media all and (min-width: 1024px){
					padding: 40px 0 0 0;
				}
				.unformatted-row{
					border-bottom: 1px solid #ebebeb;
					&:last-child{
						border: none;
					}
					.single-element{
						&--inner{
							flex-direction: column;
							padding: 0 3%;
							@media all and (min-width: 1024px){
								flex-direction: row;
							}
							&.material{
								flex-direction: column;
								h3 a {
									font-size: map-get($fontSizeMap, small);
									@media all and (min-width: 1024px){
										font-size: map-get($fontSizeMap, middleXl);
									
									}
								}
							}
							&.two-sides{
								flex-direction: row;
								padding: 30px 0;
								.single-element--inner--left{
									margin: 0 5% 0 0;
								}
								.single-element--inner--right{
									width: 100%;
								}
							}
						}
					}
				}
			}
			
		}
		&--author{
			margin: 0 0 18px 58px;
			display: block;
			a{
				color: $text-grey;
				font-weight: bold;
				font-size: map-get($fontSizeMap, smallXl);
			
			}
		}
		&--title{
			border-top: 1px solid rgba(153, 153, 153, 0.2);
			padding: 19px 0;
			font-size: 9vw;
			font-family: 'Noto Serif', serif;
			border-bottom: 1px solid rgba(153, 153, 153, 0.2);
			margin: 0;
			color: $dark-grey;
			text-align: center;
			@media all and (min-width:768px){
				font-size: map-get($fontSizeMap, largeXl);
				padding: 29px 0 32px 58px;
			}
			@media all and (min-width:1024px){
				margin: 0 0 66px 0;
				text-align: left;
			}
		}
		&--content{
			padding: 0 58px 0 58px;
			color: $text-color;
		}
		&--image{
			
		}
		
	}
	
	img{
	    margin: 0 auto;
		max-width: 171px;
		width: 100%;
		@media all and (min-width: 1024px){
			margin: 0 38px 0 0;
		}
	}
	p{
		color: $text-grey;
		//display: inline;
		font-size: map-get($fontSizeMap, minXl);
		@media all and (min-width: 1024px){
			font-size: map-get($fontSizeMap, small);
		
		}
	}
	a{
		color: $dark-grey;
		font-size: map-get($fontSizeMap, minXl);
		&:hover{
			color: $light-green;
		}
		@media all and (min-width: 1024px){
			font-size: map-get($fontSizeMap, small);
		
		}	}
	.pop-list li a{
		background-color: transparent;
		color: $dark-grey;
		display: block;
		padding: 10px 0px 16px 0;
		height: initial;
		text-transform: none;
		&:after{
			content: '';
			width: 30px;
			height: 30px;
			border: 1px solid rgba(153, 153, 153, 0.2);
			display: block;
			float: right;
		}
		&.active{
			&:after{
				background: url('../images/check.png') center no-repeat;
			}
		}
		&:hover{
			color: $light-green;
		}
	}
	.pop-list li{
		margin: 0;
		display: block;

		border-bottom: 1px solid rgba(153, 153, 153, 0.2);
	}
}