$loaderSize: 100px;
$loaderBackground: rgba(0, 0, 0, 0.5);

$loaderSpiralSize: 10px;
$loaderFirstSpiralColor: rgb(29, 174, 236);
$loaderSecondSpiralColor: rgb(66, 66, 66);
$loaderThirdSpiralColor: rgb(255, 255, 255);

$loaderAnimationTime: 1.15s;

.preloader {
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    opacity: 1;
    position: fixed;
    visibility: visible;
    z-index: 100;
    transition: all 0.6s ease-in-out;
    background-color: $loaderBackground;
    > div {
        transition: all 0.6s ease-in-out;
        position: relative;
        top: 50%;
        left: 50%;
        margin: -($loaderSize / 2);
        width: $loaderSize;
        height: $loaderSize;
    }
    &.hide-me {
        opacity: 0;
        visibility: hidden;
        > div {
            transform: scale(0);
        }
    }
}

.cssload-loader {
    position: relative;
    top: 50%;
    left: 50%;
    margin: -($loaderSize / 2);
    width: $loaderSize;
    height: $loaderSize;
    border-radius: 50%;
    perspective: 3300px;
    .cssload-inner {
        position: absolute;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border-radius: 50%;
        &.cssload-one {
            left: 0;
            top: 0;
            animation: cssload-rotate-one $loaderAnimationTime linear infinite;
            border-bottom: $loaderSpiralSize solid $loaderFirstSpiralColor;
        }
        &.cssload-two {
            right: 0;
            top: 0;
            animation: cssload-rotate-two $loaderAnimationTime linear infinite;
            border-right: $loaderSpiralSize solid $loaderSecondSpiralColor;
        }
        &.cssload-three {
            right: 0;
            bottom: 0;
            animation: cssload-rotate-three $loaderAnimationTime linear infinite;
            border-top: $loaderSpiralSize solid $loaderThirdSpiralColor;
        }
    }
}
