.pager{
	margin: 15px 0 0 0;
	ul{
		text-align: center;
		&:before, &:after{
			clear: both;
			content: '';
			display: block;
		}
		li{
			display: inline-block;
			margin: 0 10px;
		}
	}
}

.pager__item.is-active a {
	font-weight: bold;
}