/*font-family: 'Noto Serif', serif;*/

.main{
	&--news{
		background: $news-bg;
		overflow: hidden;
		&--inner{
			max-width: 1366px;
			margin: 0 auto;
			width: 100%;
			padding: 40px 0;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			@media all and (min-width: 1024px){
				flex-direction: row;
				padding: 90px 0;
			
			}
			&--left-col{
				
				padding: 0 5%;
				@media all and (min-width: 1024px){
					min-width: 50%;
					padding: 0 50px;
					flex-grow: 1;
				
				}
			}
			&--right-col{
				padding: 0 5%;
				@media all and (min-width: 1024px){
					padding: 0 50px;
					min-width: 50%;
					flex-grow: 1;
				}
				@media all and (min-width: 1440px){
					padding: 0 0 0 50px;
				
				}
			}
		}
		&--category{
			&--read-more{
				width: 100%;
				border: 4px solid #b5b9aa;
				display: block;
				padding: 20px 20px;
				text-align: center;
				color: $dark-grey;
				font-size: map-get($fontSizeMap, minXl);
				&:hover{
					color: $light-green;
				}
				@media all and (min-width:1024px){
					font-size: map-get($fontSizeMap, small);

				}
				@media all and (max-width:1024px){
					margin-bottom: 50px;
				}
			}
		}
	}
}
.main--news--inner--left-col ~ .main--news--inner--right-col{
	border-left: 1px solid #ebebeb;
}
.title-news{
	font-family: 'Noto Serif', serif;
	color: $dark-grey;
	span{
		display: block;
		&:first-child{
			font-size: map-get($fontSizeMap, smallXl);
			line-height: 1;
			@media all and (min-width:1024px){
				font-size: map-get($fontSizeMap, middle);
			
			}
		}
		&:last-child{
			font-size: map-get($fontSizeMap, middle);
			@media all and (min-width:1024px){
				font-size: map-get($fontSizeMap, middleXl);
			
			}
		}
	}
	&--border{
		width: 100%;
		border-bottom: 5px solid $light-green;
		margin: 0 0 8px 27px;
		flex: 1;
		@media all and (min-width: 1024px){
			margin: 0 0 14px 27px;
		
		}
	}
	&--box{
		display: flex;
		flex-direction: row;
		margin: 0 0 34px 0;
		@media all and (min-width:1024px){		
			margin: 0 0 64px 0;
		}
	}
}

.events{
	position: relative;
	
	@media all and (min-width: 1024px){
		margin: 0 0 0 -12px;
	}
	&--inner{
	
	}
	&--single-event{
		overflow: hidden;
		margin: 0 0 50px 0;
		display: flex;
		flex-direction: row;
		@media all and (min-width:1024px){
			margin: 0 0 90px 0;
		
		}
		&--date-box{

			margin: 0px 20px 0 0;
			&--top{
				width: 100%;
				border: 1px solid rgba(153, 153, 153, 0.2);
				text-align: center;
				color: #999;
				padding: 10px 15px 10px;
				@media all and (min-width:1024px){
				
					padding: 25px 30px 19px 30px;
				}
				span{
					display: block;
					font-size: map-get($fontSizeMap, smallXl);
					font-weight: bold;
					margin: 0px 0 4px 0;
					line-height: 1;
					@media all and (min-width:1024px){
						font-size: map-get($fontSizeMap, middleXl);
			
					}
					&:first-child{
						font-size: map-get($fontSizeMap, small);
						margin: 0;
						@media all and (min-width:1024px){
							font-size: map-get($fontSizeMap, smallXl);
			
						}
					}
					&:last-child{
						font-size: map-get($fontSizeMap, minXl);
						margin: 0;
						@media all and (min-width:1024px){
							font-size: map-get($fontSizeMap, smallXl);
			
						}
					}
				}
			}
			&--bottom{
				width: 100%;
				border: 1px solid rgba(153, 153, 153, 0.2);
				text-align: center;
				color: #999;
				padding: 10px 0;
				font-size: map-get($fontSizeMap, small);
				font-weight: bold;
				border-top: none;
			
			}
			
		}
		&--content-box{
			//width: 67%;
			//float: right;
			flex:1;
			h3 a{
				color: $dark-grey;
				font-weight: bold;
				font-size: map-get($fontSizeMap, small);
				margin: 0 0 10px 0;
				font-family: 'Noto Serif', serif;
				@media all and (min-width:1024px){
					font-size: map-get($fontSizeMap, smallXl);
				
				}
			}
			&--description{
				color: $text-grey;
				font-size: map-get($fontSizeMap, minXl);
				@media all and (min-width:1024px){
					font-size: map-get($fontSizeMap, small);
				
				}
			}
			&--date{
				color: $text-grey;
				display: block;
				font-size: map-get($fontSizeMap, minXl);
				margin: 17px 0 0 0;

			}
			&--link{
				display: block;
				margin: 10px 0;
				@media all and (min-width: 1024px){
					text-align: right;
					margin:0;
				
				}
				a{
					color: $dark-grey;
					font-size: map-get($fontSizeMap, minXl);
					&:hover{
						color: $light-green;
					}
					@media all and (min-width:1024px){
						font-size: map-get($fontSizeMap, small);
					
					}
				}
			}
		}
	}
}

.blog-news{
	width: 100%;
	@media all and (min-width:1024px){
		padding: 11px 0 0 0;
		width: 92%;
	
	}
	&--single{
		overflow: hidden;
		margin: 0 0 72px 0;
		display: flex;
		flex-direction: row;
	}
	&--photo{
		width: 100px;
		margin: 0 20px 0px 0;
		float: left;
		@media all and (min-width:1024px){
			width: 147px;
			min-width: 147px;
		}
		img{
			padding: 8px;
			border: 1px solid rgba(153,153,153,.2);
			display: block;
			margin: 0 auto;
			width: 100%;
			
		}
		
	}
	&--content{
		flex: 1;
		&--name{
			font-weight: bold;
			color: $text-grey;
			font-size: map-get($fontSizeMap, minXl);
			@media all and (min-width:1024px){
				font-size: map-get($fontSizeMap, small);
			
			}
		}
		&--date{
			color: $text-grey;
			font-size: map-get($fontSizeMap, min);
			float: right;
			@media all and (min-width:1024px){
				font-size: map-get($fontSizeMap, min);
			
			}
		}
		&--title{
			font-family: 'Noto Serif', serif;
			display: block;
			margin: 8px 0 13px 0;
			font-size: map-get($fontSizeMap, small);
			font-weight: bold;
			line-height: 1.2;
			@media all and (min-width:1024px){
				font-size: map-get($fontSizeMap, middle);
			
			}
		}
		&--description{
			color: $text-grey;
			font-size: map-get($fontSizeMap, minXl);
			@media all and (min-width:1024px){
				font-size: map-get($fontSizeMap, small);
			
			}
			&--link{
				a{
					color: $dark-grey;
					font-size: map-get($fontSizeMap, minXl);
					@media all and (min-width:1024px){
						font-size: map-get($fontSizeMap, small);
					
					}
				}
			}
		}
	}
}














