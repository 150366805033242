.slick-slide:not(.slick-current){visibility: hidden}

.slick-slider {
    margin-bottom: 0;
    position:relative;
}
ul.slick-slide {
    padding-left:0;
    max-width:100%;
}
.slick-slider .slick-track {
    min-width:100%;
}
ul.slick-slide li img, .slick-dots button img {
    width:100%;
    height:auto;
}
.slick-dots {
    bottom:0;
    top:auto;
    left:auto;
    right:auto;
    position:relative;
    line-height:0;
	width: calc(100% - 108px);
    margin: 0 50px 0 58px;
}

.slick-dots li, .slick-dots li button:before {
   /* width: 100px;
    height:100px; */
    margin:0;
    padding:0;
}
.slick-dots li {
    margin: 5px; border:1px solid #ccc; display:inline-block; width:auto;height:auto;
}

.slick-slider .slick-track {
    min-width:100%;
}
.slick-dots li button {
    margin:0;
    height:auto;
    width:100%;
    padding:0;

}

.slick-dots li button.tab { clear:both;   display:block;}
.slick-dots li button img { width:100%; max-width:80px; height:auto;   }
.slick-dots li button:before {
    content:''; border:1px solid #f00;display:block; width:100%;  max-width:150px; overflow:hidden; float:none;  position:absolute;
    width:100%;
    height:100%}

.slick-thumbs {
    position:absolute;
    left: -9999px;
}
.slick-next, .slick-prev{
	width: 54px;
	height: 106px;

}
.slick-next{
	background: url('../images/right-slider-arrow.png') no-repeat;
	&:focus, &:hover{
		background: url('../images/right-slider-arrow.png') no-repeat;
	
	}
} 

.slick-prev{
	background: url('../images/left-slider-arrow.png') no-repeat;
	&:focus, &:hover{
		background: url('../images/left-slider-arrow.png') no-repeat;
	
	}
}
.slick-list{
    margin: 0 3% 0 3%;
	@media all and (min-width: 1024px){
		margin: 0 50px 0 58px;
	
	}
}
.slick-dots li button:before{
	border: 2px solid transparent;
}
.slick-dots li.slick-active button:before{
	border: 2px solid $light-grey;
}
