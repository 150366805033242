#nav-icon1 {
	width: 37px;
    height: 23px;
	position: absolute;
	transform: rotate(0deg);
	transition: .5s ease-in-out;
	cursor: pointer;
	right: 30px;
	top: 43px;
	z-index: 1;
	
}

#nav-icon1 span {
	display: block;
	position: absolute;
	height: 2px;
	width: 100%;
	background: $dark-grey;
	border-radius: 9px;
	opacity: 1;
	left: 0;
	transform: rotate(0deg);
	transition: .25s ease-in-out;
}
#nav-icon1 span:nth-child(1) {
	top: 0px;
}

#nav-icon1 span:nth-child(2) {
	top: 9px;
}

#nav-icon1 span:nth-child(3) {
	top: 19px;
}

.open #nav-icon1 span:nth-child(1) {
	top: 9px;
	transform: rotate(135deg);
	width: 100%;
	background: $text-grey;
}

.open #nav-icon1 span:nth-child(2) {
	opacity: 0;
	left: -60px;
}

.open #nav-icon1 span:nth-child(3) {
	top: 9px;
	width: 100%;
	transform: rotate(-135deg);
	background: $text-grey;
}
