.bpc-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 100vh;
    width: 100vw;
    text-align: center;
    background: rgba(0,0,0,0.7);
	&--inner{
		width: 60vw;
		margin: auto;
		height: 85vh;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		&--close{
			position: absolute;
			top: 0;
			right: 0;
			text-indent: -999999px;
			background: url('../images/square-close-button-white.png') 0 0 no-repeat;
			width: 25px;
			height: 25px;
			display: block;
			cursor: pointer;
		}
		&--image{
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			top: 0;
			bottom: 0;
			width: auto;
			height: inherit;
			background: #fbfbfb;
			padding: 3% 7%;
		}
	}
}