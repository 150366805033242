.news-page{
	margin: 60px 0;
	&--inner{
		display: flex;
		flex-direction: row;
		width: 100%;
		&--left-container{
			width: 62.6%;
			&--inner{
				max-width: 924px;
				width: 100%;
				float: right;
				padding: 0 119px 0 0;
			}
			
		}
		&--right-container{
			flex: 1;
			position: relative;
			background: $news-bg;
			&--inner{
				max-width: 449px;
				float: left;
				padding: 56px 0 65px 56px;
				&--title{
					font-size: map-get($fontSizeMap, smallXl);
					font-family: 'Noto Serif',serif;
					border-bottom: 5px solid $grey;
					width: 100%;
					display: block;
					padding-bottom: 18px;
					margin: 0 0 35px 0;
				}
			}
		}
		&--author{
			color: $name-blue;
			font-weight: bold;
			font-size: map-get($fontSizeMap, smallXl);
			margin: 0 0 18px 58px;
			display: block;
		}
		&--title{
			padding: 37px 0 34px 58px;
			font-size: map-get($fontSizeMap, big);
			font-family: 'Noto Serif', serif;
			display: inline;
			width: 74%;
			float: right;
			margin: 0 0 60px 0;
		}
		&--content{
			clear: both;
			overflow: hidden;
			margin-top: 50px;
		}
	}
	.events--single-event--date-box{
		width: 26%;
		float: left;
		margin: 11px 0 0;
		display: inline-block;
	}
	p{
		margin: 0 0 30px;
		font-size: map-get($fontSizeMap, small);
		color: $text-color;
	}
	ul{
		margin: 0 0 60px 0;
		li{
			padding: 0 0 0 10px;
			display: inline-flex;
			color: $text-color;
			&:before{
				content: "\002022";
				color: #afafaf;
				padding: 0 10px 0 0px;
			}
		}
	}
	h1{
		margin: 20px 0 25px 0;
		font-size: map-get($fontSizeMap, middle);
		font-family: 'Noto Serif', serif;
		color: $dark-grey;
		font-weight: bold;
	}
	h2{
		margin: 60px 0 20px 0;
		font-size: map-get($fontSizeMap, smallXl);
		font-family: 'Noto Serif', serif;
		color: $dark-grey;
		font-weight: bold;
	}
	h3{
		margin: 20px 0 45px 0;
		font-size: map-get($fontSizeMap, small);
		font-family: 'Noto Serif', serif;
		font-weight: bold;
		color: $dark-grey;
	}
	.text-align-center{
		text-align: center;
	}
	.text-align-left{
		text-align: left;
	}
	.text-align-right{
		text-align: right;
	}
	img[data-align=center] {
		text-align: center;
		margin: 50px auto;
		display: block;
		border: 1px solid $light-grey;
		padding: 20px;
	}
	img[data-align=right] {
		text-align: right;
		float: right;
		margin: 50px 0 50px 20px;
		border: 1px solid $light-grey;
		padding: 20px;
	}
	img[data-align=left] {
		text-align: left;
		float: left;
		margin: 50px 20px 50px 0;
		border: 1px solid $light-grey;
		padding: 20px;
	}
}