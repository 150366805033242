.text-page{
	margin: 60px 0;
	font-family: 'Noto Serif',serif;
	*{
		font-family: 'Noto Serif',serif;
		color: $text-color;
	
	}
	&--inner{
		display: flex;
		flex-direction: column-reverse;
		width: 100%;
		@media all and (min-width:1024px){
			flex-direction: row;
		
		}
		&--left-container{
			width: 100%;
			@media all and (min-width: 1024px){
				width: 62.6%;
			
			}
			&--inner{
				float: right;
				padding: 50px 3%;
				width: 100%;
				@media all and (min-width: 1024px){
					padding: 103px 83px 0 0;
					max-width: 871px;
				
				}
			}
			
		}
		&--right-container{
			flex: 1;
			position: relative;
			background: $news-bg;
			&--inner{
				max-width: 442px;
				float: left;
			}
			a{
				font-family: 'Noto Serif', serif;
				font-size: map-get($fontSizeMap, small);
				color: $dark-grey;
				&:hover{
					color:$light-green;
				}
			}
			.unformatted-list--inner--right-container--filter-box{
				margin-bottom: 1em;
			}
			.unformatted-list--inner--right-container--inner{
				padding: 106px 0 65px 86px;
			}
		}
		&--author{
			margin: 0 3% 18px 3%;
			display: block;
			@media all and (min-width: 1024px){
				margin: 0 0 18px 51px;
			
			}
			a{
				color: $text-grey;
				font-weight: bold;
					font-size: map-get($fontSizeMap, small);
				&:hover{
					color: $name-blue;
				
				}
				@media all and (min-width: 1024px){
				font-size: map-get($fontSizeMap, smallXl);
				
				}
			}
		}
		&--title{
			border-top: 1px solid rgba(153, 153, 153, 0.2);
			border-bottom: 1px solid rgba(153, 153, 153, 0.2);
			padding: 16px 3% 16px 3%;
			margin: 0 0 30px;
			line-height: 1.25;
			@media all and (min-width: 1024px){
				margin: 0 0 60px;
				padding: 36px 0 36px 50px;
			
			}
			a{
				font-family: 'Noto Serif', serif;
					font-size: map-get($fontSizeMap, middle);
				color: $dark-grey;
				&:hover{
					color:$light-green;
				}
				@media all and (min-width: 1024px){
					font-size: map-get($fontSizeMap, big);
					
				}
			}
		}
		&--content{
			padding: 0 3%;
			@media all and (min-width: 1024px){
				padding: 0 0 0 50px;
			
			}
		}
		i{
			font-style: italic;
		}
		h2{
			font-size: 1.5rem;
			font-weight: 700;
			color: $dark-grey;
			@media all and (min-width: 1024px){
				font-size: 1.87rem;
			}
		}
		h3{
			font-size: 1.2rem;
			font-weight: 700;
			margin: 15px 0 0 0;
			color: $dark-grey;
			@media all and (min-width: 1024px){
				font-size: 1.52rem;
			}
		}
		h4{
			margin: 17px 0 0 0;
			font-size: 1rem;
			font-weight: 700;
			color: $dark-grey;
			@media all and (min-width: 1024px){
				font-size: 1.14rem;
			}
		}
		p{
			line-height: 1.45;
			color: $text-color;
			margin: 20px 0 0 0;
			font-size: 0.9rem;
			& + h4{
				margin: 55px 0 0 0;
				@media all and (min-width: 1024px){
					margin: 85px 0 0 0;
				
				}
			}
			& + p{
				margin: 21px 0 0 0;
			}
			@media all and (min-width: 1024px){
				margin: 40px 0 0 0;
				font-size: 1.07rem;
			}
		}
		ul{
			font-size: 0.95rem;
			line-height: 1.2;
			display: block;
			color: $text-color;
			padding: 0;
			margin: 30px 0 50px;
			@media all and (min-width: 1024px){
				margin: 30px 0 80px 0;
				padding: 0 0 0 99px;
			}
		}
		img{
		    padding: 2.4%;
			width: 100%;
			margin: 45px 0 13px 0;
			border: 1px solid #ebebeb;
			@media all and (min-width: 1024px){
				width: 105%;
				margin: 45px 0 13px -19px;
			
			}
		}
		blockquote{
			display: none;
			padding: 5% 10% 5% 5%;
			background: #fbfbfb;
			margin: 15px 0;
			position: relative;
			width: 100%;
			span{
				position: absolute;
				top: 5%;
				right: 1.3%;
				text-indent: -999999px;
				background: url('../images/square-close-button.png') 0 0 no-repeat;
				width: 25px;
				height: 25px;
				cursor: pointer;
			}
			&.open{
				display: block;
			}
		}
		q{
			margin: 0 5px 0 -3px;
			color: #6483bd;
			cursor: pointer;
			font-size: 70%;
			vertical-align: top;
			color: $name-blue;
		}
		.cite-box{
			position: absolute;
			left: 20px;
			display: none;
			@media all and (min-width: 1024px){
				display: block;
			}
			span{
				color: $text-color;
				font-size: 1rem;
				
				margin: 0 16px 0 0;
			}
			button{
				background: none;
				border: 1px solid #b5b9aa;
				color: #b5b9aa;
				font-weight: 700;
				font-size: 1rem;
				padding: 4px 29px;
				cursor: pointer;
				&:hover{
					border: 1px solid $name-blue;
					color: $name-blue;
				}
			}
			
		}
		
	}
}

.bpc-popup.cite{
	.bpc-popup--inner{
		background: #fbfbfb;
		width: 50vw;
		height: 55vh;
		padding: 20px;
		*{
			color: $dark-grey;
		}
		.bpc-popup--inner--close{
			background: url('../images/square-close-button.png') 0 0 no-repeat;
			top: 10px;
			right: 10px;
		}
	}
	
}