.single-element{
	margin: 20px 0;
	@media all and (min-width: 1024px){
		margin: 40px 0;
	
	}
	&--inner{
		display: flex;
		flex-direction: column-reverse;
		width: 100%;
		//padding: 0 70px 0 0;
		@media all and (min-width: 1024px){
			flex-direction: row;
		
		}
		&.news{
			flex-direction: column;

		}
		&--description{
			margin: 36px 0 0 0;
			color: #999;
			@media all and (min-width: 1024px){
				max-width:calc(100% - 209px);
			
			}
		}
		&--left-container{
			width: 100%;
			@media all and (min-width: 1024px){
				width: 62.6%;
			
			}
			&--inner{
				float: right;
				padding: 30px 3% 0;
				width: 100%;
				@media all and (min-width: 1024px){
					padding: 103px 29px 0 50px;
					max-width: 924px;
				
				}
				&.material{
					min-width: 80%;
				}
			}
			
		}
		&--right-container{
			flex: 1;
			position: relative;
			background: $news-bg;
			
			&--inner{
				max-width: 100%;
				float: left;
				padding: 7vh 5vw 20px;
				
			}
		}
		&--author{
			margin: 0px 0 18px 3%;
			display: block;
			@media all and (min-width: 1024px){
				margin: 0 0 18px 58px;
			
			}
			a{
				color: $text-grey;
				font-weight: bold;
				font-size: map-get($fontSizeMap, small);
				@media all and (min-width:1024px){
					font-size: map-get($fontSizeMap, smallXl);
				
				}
			}
		}
		&--title{
			border-top: 1px solid rgba(153, 153, 153, 0.2);
			padding: 29px 3% 32px 3%;
			font-size: map-get($fontSizeMap, middle);
			font-family: 'Noto Serif', serif;
			border-bottom: 1px solid rgba(153, 153, 153, 0.2);
			margin: 0 0 26px 0;
			color: $dark-grey;
			display: flex;
			flex-direction: column;
			align-items: center;
			@media all and (min-width: 486px){
				align-items: initial;
				flex-direction: row;
			}
			@media all and (min-width: 1024px){
				margin: 0 0 66px 0;
				display: block;
				font-size: map-get($fontSizeMap, middleXl);
				padding: 29px 0 32px 58px;
			
			}
			&.single-event{
				display: flex;
				
			}
			&--image{
				margin: 0 2vw 0 0;
				flex-grow: 1;
				height: 223px;
				@media all and (min-width: 486px){
					min-width: 33%;
					max-width: 33%;
					height: initial;
				}
			
			}
			&--titles{
				flex-grow: 1;
				min-width: 67%;
				word-wrap: break-word;
			}
		}
		&--subtitle{
			padding: 9px 0 0 0	;
			font-size: map-get($fontSizeMap, small);
			font-family: 'Noto Serif', serif;
			color: $dark-grey;
			@media all and (min-width: 1024px){
				font-size: map-get($fontSizeMap, smallXl);
			
			}
		}
		&--content{
			padding: 0 3%;
			color: $text-color;
			@media all and (min-width: 1024px){
				padding: 0 58px 0 58px;
			
			}
			article.embedded-entity {
				width: auto;
				height: auto;
			}
			article.embedded-entity img {
				max-width: 100%;
				max-height: 100%;
				height: auto;
			}
			&--section{
				padding: 20px 0 0 0;
				color: $text-color;
				@media all and (min-width: 1024px){
					padding: 110px 0 0 0;
				
				}
				&.no-padding{
					padding: 0;
				}
				> div > div:first-child {
					font-size: map-get($fontSizeMap, smallXl);
					color: $dark-grey;
					font-weight: bold;
					font-family: 'Noto Serif', serif;
					padding: 0 3% 10px;
					margin: 0 0 25px 0;
					border-bottom: 5px solid #b5b9aa;
					@media all and (min-width: 1024px){
						padding: 0 0 9px 0;
						margin: 0 50px 45px 58px;
						font-size: map-get($fontSizeMap, middle);
					
					}
				}
				> div div > ol{
					margin: 0 50px 0 0;
				}
				div > p {
					padding: 0 3%;
					@media all and (min-width: 1024px){
						padding: 0 58px;
					
					}
				}
				h4:first-child {
					margin-top: 0;
				}
				h4 {
					font-size: map-get($fontSizeMap, smallXl);
					color: $dark-grey;
					font-weight: bold;
					font-family: 'Noto Serif', serif;
					padding: 0 3% 9px;
					margin: 45px 0 45px 0;
					border-bottom: 5px solid #b5b9aa;
					@media all and (min-width: 1024px){
						font-size: map-get($fontSizeMap, middle);
						padding: 0 0 9px 0;
						margin: 45px 50px 45px 58px;
					
					}
				}
				.video-container{
					margin: 0 3% 0 3%;
					width: 94%;
					@media all and (min-width: 1024px){
						width: calc(100% - 108px);
						margin: 0 50px 0 58px;
					
					}
					li{
						padding: 0 0 20px 0;
					}
				}
				.documents {
					margin-bottom: 3em;
				}
			}
		}
		&--img-link{
			margin: 0 38px 0 0;
				min-width: 171px;
			
		}
		&--image{
			img{
				height: 223px;
				width: auto;
				@media all and (min-width: 486px){
					height:initial;
					width: 100%;
				
				}
			}
		}
		&--date-box{

			margin: 0px 20px 0 0;
			&--top{
				width: 100%;
				border: 1px solid rgba(153, 153, 153, 0.2);
				text-align: center;
				color: #999;
				padding: 15px 20px 9px 20px;
				@media all and (min-width:1024px){
					padding: 10px 15px 10px;
				}
				span{
					display: block;
					font-size: map-get($fontSizeMap, smallXl);
					font-weight: bold;
					margin: 0px 0 4px 0;
					line-height: 1;
					@media all and (min-width:1024px){
						font-size: map-get($fontSizeMap, middleXl);
			
					}
					&:first-child{
						font-size: map-get($fontSizeMap, small);
						margin: 0;
						@media all and (min-width:1024px){
							font-size: map-get($fontSizeMap, smallXl);
			
						}
					}
					&:last-child{
						font-size: map-get($fontSizeMap, minXl);
						margin: 0;
						@media all and (min-width:1024px){
							font-size: map-get($fontSizeMap, smallXl);
			
						}
					}
				}
			}
			&--bottom{
				width: 100%;
				border: 1px solid rgba(153, 153, 153, 0.2);
				text-align: center;
				color: #999;
				padding: 10px;
				font-size: map-get($fontSizeMap, small);
				font-weight: bold;
				border-top: none;
			
			}
			
		}
	}
	ol{
		overflow: hidden;
		width: 100%;
		&.video-container{
			li{
				padding: 0 0 20px 0;
			}
		}
		li{
			border-bottom: 1px solid rgba(153,153,153,.2);
			padding: 0 7% 11px 3%;
			margin: 0 0 12px;
			font-size: map-get($fontSizeMap, small);
			position: relative;
			@media all and (min-width: 1024px){
				padding: 0 10% 11px 58px;
				margin: 0 0 12px 0;
			
			}
			.arrow{
				background: url('../images/square-arrow-bottom-grey.png') 0 0 no-repeat;
				background-position: 94% 50%;
				cursor: pointer;
				display: block;
				width: 25px;
				height: 25px;
				position: absolute;
				top:0;
				right: 0;
			}

			&.expand{
				background: none;
				
				display: block;
				position: relative;
				padding: 0 0% 11px 3%;
				@media all and (min-width: 1024px){
					padding: 0 0 11px 58px;
				
				}
				
				> .arrow{
					background: url('../images/square-arrow-top-grey.png') 0 0 no-repeat;
					
				}
				
			}
			a{
				color: $text-color;
				&:hover{
					color:$light-green;
				}
			}
			h5{
				margin: 0 0 20px 0;
				font-size: map-get($fontSizeMap, smallXl);
				color: #262626;
				font-style: italic;
				font-weight: 700;
				font-family: 'Noto Serif',serif;
			}
		}
		
		ol{
			max-height: 0;
			overflow: hidden;
			li{
				font-size: map-get($fontSizeMap, small);
				&:last-child{
					border: none;
				}
			}
			ol{
				li{
					font-size: map-get($fontSizeMap, small);
					&:last-child{
						border: none;
					}
				}
				ol{
					li{
						font-size: map-get($fontSizeMap, minXl);
						&:last-child{
							border: none;
						}
					}
				}
			}
		}
	}
	img{
		//width: 100%;
		//height: 100%;
	}
	.link--download{
		text-align: center;
		display: block;
		margin: 0;
		border: 1px solid $grey;
		padding: 15px;
		font-size: map-get($fontSizeMap, minXl);
		color: $grey;
		font-weight: bold;
		@media all and (min-width: 1024px){
			margin: 42px 0 0 0;
			font-size: map-get($fontSizeMap, small);
		
		}
	}
	.button-expand-all{
		float: right;
		border: 1px solid $grey;
		padding: 5px 15px;
		cursor: pointer;
		margin: 0 0 30px 0;
		transition: all 0.3s;
		&:hover{
			background: $grey;
			color: $dark-grey;
		}
	}
}
