@charset "UTF-8";
@import url(../../../../../node_modules/reset.css/reset.css);
/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.2; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.8;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.2;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.8; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

html {
  font-size: 16px; }

body {
  line-height: 1.3;
  font-family: 'Open Sans', sans-serif; }

ol,
ul {
  margin: 0;
  padding: 0; }

* {
  box-sizing: border-box; }
  *:after, *:before {
    box-sizing: border-box; }

a {
  text-decoration: none; }
  a:visited {
    color: initial; }

em {
  font-style: italic; }

strong {
  font-weight: bold; }

sup {
  font-size: 0.7em;
  vertical-align: super; }

sub {
  font-size: 0.7em;
  vertical-align: text-bottom; }

p {
  margin-bottom: 1em; }

figure {
  margin: 1.5em 0; }

figcaption {
  padding: 0 10px;
  font-size: 0.8em; }

.visually-hidden {
  display: none; }

.desktop-view {
  display: none; }
  @media all and (min-width: 1024px) {
    .desktop-view {
      display: block; } }

.mobile-view {
  display: block; }
  @media all and (min-width: 1024px) {
    .mobile-view {
      display: none; } }

#block-bpc-local-tasks.contextual-region {
  border-bottom: 1px solid #b5b9aa;
  overflow: hidden;
  margin: 30px 0 0 0; }
  #block-bpc-local-tasks.contextual-region ul {
    width: 1360px;
    margin: 0 auto; }
    #block-bpc-local-tasks.contextual-region ul li {
      float: left;
      margin: 0 6px 0px 0; }
      #block-bpc-local-tasks.contextual-region ul li a {
        border: 1px solid #b5b9aa;
        border-bottom: none;
        border-radius: 5px 5px 0 0;
        padding: 5px 11px;
        background: #f7f8f6;
        display: block;
        color: #262626; }
        #block-bpc-local-tasks.contextual-region ul li a.is-active {
          background: #fff; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }
  .container:after, .container:before {
    content: " ";
    display: table; }
  .container:after {
    clear: both; }
  @media (min-width: 768px) {
    .container {
      width: 750px; } }
  @media (min-width: 992px) {
    .container {
      width: 970px; } }
  @media (min-width: 1200px) {
    .container {
      width: 1170px; } }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }
  .container-fluid:after, .container-fluid:before {
    content: " ";
    display: table; }
  .container-fluid:after {
    clear: both; }

.row {
  margin-left: -15px;
  margin-right: -15px; }
  .row:after, .row:before {
    content: " ";
    display: table; }
  .row:after {
    clear: both; }

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 5px;
  padding-right: 5px; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left; }

.col-xs-1 {
  width: 8.3%; }

.col-xs-2 {
  width: 16.7%; }

.col-xs-3 {
  width: 25%; }

.col-xs-4 {
  width: 33.3%; }

.col-xs-5 {
  width: 41.7%; }

.col-xs-6 {
  width: 50%; }

.col-xs-7 {
  width: 58.3%; }

.col-xs-8 {
  width: 66.7%; }

.col-xs-9 {
  width: 75%; }

.col-xs-10 {
  width: 83.3%; }

.col-xs-11 {
  width: 91.7%; }

.col-xs-12 {
  width: 100%; }

.col-xs-pull-0 {
  right: auto; }

.col-xs-pull-1 {
  right: 8.3%; }

.col-xs-pull-2 {
  right: 16.7%; }

.col-xs-pull-3 {
  right: 25%; }

.col-xs-pull-4 {
  right: 33.3%; }

.col-xs-pull-5 {
  right: 41.7%; }

.col-xs-pull-6 {
  right: 50%; }

.col-xs-pull-7 {
  right: 58.3%; }

.col-xs-pull-8 {
  right: 66.7%; }

.col-xs-pull-9 {
  right: 75%; }

.col-xs-pull-10 {
  right: 83.3%; }

.col-xs-pull-11 {
  right: 91.7%; }

.col-xs-pull-12 {
  right: 100%; }

.col-xs-push-0 {
  left: auto; }

.col-xs-push-1 {
  left: 8.3%; }

.col-xs-push-2 {
  left: 16.7%; }

.col-xs-push-3 {
  left: 25%; }

.col-xs-push-4 {
  left: 33.3%; }

.col-xs-push-5 {
  left: 41.7%; }

.col-xs-push-6 {
  left: 50%; }

.col-xs-push-7 {
  left: 58.3%; }

.col-xs-push-8 {
  left: 66.7%; }

.col-xs-push-9 {
  left: 75%; }

.col-xs-push-10 {
  left: 83.3%; }

.col-xs-push-11 {
  left: 91.7%; }

.col-xs-push-12 {
  left: 100%; }

.col-xs-offset-0 {
  margin-left: 0%; }

.col-xs-offset-1 {
  margin-left: 8.3%; }

.col-xs-offset-2 {
  margin-left: 16.7%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-4 {
  margin-left: 33.3%; }

.col-xs-offset-5 {
  margin-left: 41.7%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-7 {
  margin-left: 58.3%; }

.col-xs-offset-8 {
  margin-left: 66.7%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-10 {
  margin-left: 83.3%; }

.col-xs-offset-11 {
  margin-left: 91.7%; }

.col-xs-offset-12 {
  margin-left: 100%; }

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left; }
  .col-sm-1 {
    width: 8.3%; }
  .col-sm-2 {
    width: 16.7%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-4 {
    width: 33.3%; }
  .col-sm-5 {
    width: 41.7%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-7 {
    width: 58.3%; }
  .col-sm-8 {
    width: 66.7%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-10 {
    width: 83.3%; }
  .col-sm-11 {
    width: 91.7%; }
  .col-sm-12 {
    width: 100%; }
  .col-sm-pull-0 {
    right: auto; }
  .col-sm-pull-1 {
    right: 8.3%; }
  .col-sm-pull-2 {
    right: 16.7%; }
  .col-sm-pull-3 {
    right: 25%; }
  .col-sm-pull-4 {
    right: 33.3%; }
  .col-sm-pull-5 {
    right: 41.7%; }
  .col-sm-pull-6 {
    right: 50%; }
  .col-sm-pull-7 {
    right: 58.3%; }
  .col-sm-pull-8 {
    right: 66.7%; }
  .col-sm-pull-9 {
    right: 75%; }
  .col-sm-pull-10 {
    right: 83.3%; }
  .col-sm-pull-11 {
    right: 91.7%; }
  .col-sm-pull-12 {
    right: 100%; }
  .col-sm-push-0 {
    left: auto; }
  .col-sm-push-1 {
    left: 8.3%; }
  .col-sm-push-2 {
    left: 16.7%; }
  .col-sm-push-3 {
    left: 25%; }
  .col-sm-push-4 {
    left: 33.3%; }
  .col-sm-push-5 {
    left: 41.7%; }
  .col-sm-push-6 {
    left: 50%; }
  .col-sm-push-7 {
    left: 58.3%; }
  .col-sm-push-8 {
    left: 66.7%; }
  .col-sm-push-9 {
    left: 75%; }
  .col-sm-push-10 {
    left: 83.3%; }
  .col-sm-push-11 {
    left: 91.7%; }
  .col-sm-push-12 {
    left: 100%; }
  .col-sm-offset-0 {
    margin-left: 0%; }
  .col-sm-offset-1 {
    margin-left: 8.3%; }
  .col-sm-offset-2 {
    margin-left: 16.7%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-4 {
    margin-left: 33.3%; }
  .col-sm-offset-5 {
    margin-left: 41.7%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-7 {
    margin-left: 58.3%; }
  .col-sm-offset-8 {
    margin-left: 66.7%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-10 {
    margin-left: 83.3%; }
  .col-sm-offset-11 {
    margin-left: 91.7%; }
  .col-sm-offset-12 {
    margin-left: 100%; } }

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left; }
  .col-md-1 {
    width: 8.3%; }
  .col-md-2 {
    width: 16.7%; }
  .col-md-3 {
    width: 25%; }
  .col-md-4 {
    width: 33.3%; }
  .col-md-5 {
    width: 41.7%; }
  .col-md-6 {
    width: 50%; }
  .col-md-7 {
    width: 58.3%; }
  .col-md-8 {
    width: 66.7%; }
  .col-md-9 {
    width: 75%; }
  .col-md-10 {
    width: 83.3%; }
  .col-md-11 {
    width: 91.7%; }
  .col-md-12 {
    width: 100%; }
  .col-md-pull-0 {
    right: auto; }
  .col-md-pull-1 {
    right: 8.3%; }
  .col-md-pull-2 {
    right: 16.7%; }
  .col-md-pull-3 {
    right: 25%; }
  .col-md-pull-4 {
    right: 33.3%; }
  .col-md-pull-5 {
    right: 41.7%; }
  .col-md-pull-6 {
    right: 50%; }
  .col-md-pull-7 {
    right: 58.3%; }
  .col-md-pull-8 {
    right: 66.7%; }
  .col-md-pull-9 {
    right: 75%; }
  .col-md-pull-10 {
    right: 83.3%; }
  .col-md-pull-11 {
    right: 91.7%; }
  .col-md-pull-12 {
    right: 100%; }
  .col-md-push-0 {
    left: auto; }
  .col-md-push-1 {
    left: 8.3%; }
  .col-md-push-2 {
    left: 16.7%; }
  .col-md-push-3 {
    left: 25%; }
  .col-md-push-4 {
    left: 33.3%; }
  .col-md-push-5 {
    left: 41.7%; }
  .col-md-push-6 {
    left: 50%; }
  .col-md-push-7 {
    left: 58.3%; }
  .col-md-push-8 {
    left: 66.7%; }
  .col-md-push-9 {
    left: 75%; }
  .col-md-push-10 {
    left: 83.3%; }
  .col-md-push-11 {
    left: 91.7%; }
  .col-md-push-12 {
    left: 100%; }
  .col-md-offset-0 {
    margin-left: 0%; }
  .col-md-offset-1 {
    margin-left: 8.3%; }
  .col-md-offset-2 {
    margin-left: 16.7%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-4 {
    margin-left: 33.3%; }
  .col-md-offset-5 {
    margin-left: 41.7%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-7 {
    margin-left: 58.3%; }
  .col-md-offset-8 {
    margin-left: 66.7%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-10 {
    margin-left: 83.3%; }
  .col-md-offset-11 {
    margin-left: 91.7%; }
  .col-md-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left; }
  .col-lg-1 {
    width: 8.3%; }
  .col-lg-2 {
    width: 16.7%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-4 {
    width: 33.3%; }
  .col-lg-5 {
    width: 41.7%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-7 {
    width: 58.3%; }
  .col-lg-8 {
    width: 66.7%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-10 {
    width: 83.3%; }
  .col-lg-11 {
    width: 91.7%; }
  .col-lg-12 {
    width: 100%; }
  .col-lg-pull-0 {
    right: auto; }
  .col-lg-pull-1 {
    right: 8.3%; }
  .col-lg-pull-2 {
    right: 16.7%; }
  .col-lg-pull-3 {
    right: 25%; }
  .col-lg-pull-4 {
    right: 33.3%; }
  .col-lg-pull-5 {
    right: 41.7%; }
  .col-lg-pull-6 {
    right: 50%; }
  .col-lg-pull-7 {
    right: 58.3%; }
  .col-lg-pull-8 {
    right: 66.7%; }
  .col-lg-pull-9 {
    right: 75%; }
  .col-lg-pull-10 {
    right: 83.3%; }
  .col-lg-pull-11 {
    right: 91.7%; }
  .col-lg-pull-12 {
    right: 100%; }
  .col-lg-push-0 {
    left: auto; }
  .col-lg-push-1 {
    left: 8.3%; }
  .col-lg-push-2 {
    left: 16.7%; }
  .col-lg-push-3 {
    left: 25%; }
  .col-lg-push-4 {
    left: 33.3%; }
  .col-lg-push-5 {
    left: 41.7%; }
  .col-lg-push-6 {
    left: 50%; }
  .col-lg-push-7 {
    left: 58.3%; }
  .col-lg-push-8 {
    left: 66.7%; }
  .col-lg-push-9 {
    left: 75%; }
  .col-lg-push-10 {
    left: 83.3%; }
  .col-lg-push-11 {
    left: 91.7%; }
  .col-lg-push-12 {
    left: 100%; }
  .col-lg-offset-0 {
    margin-left: 0%; }
  .col-lg-offset-1 {
    margin-left: 8.3%; }
  .col-lg-offset-2 {
    margin-left: 16.7%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-4 {
    margin-left: 33.3%; }
  .col-lg-offset-5 {
    margin-left: 41.7%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-7 {
    margin-left: 58.3%; }
  .col-lg-offset-8 {
    margin-left: 66.7%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-10 {
    margin-left: 83.3%; }
  .col-lg-offset-11 {
    margin-left: 91.7%; }
  .col-lg-offset-12 {
    margin-left: 100%; } }

.cf,
.clearfix {
  *zoom: 1; }
  .cf:after, .cf:before,
  .clearfix:after,
  .clearfix:before {
    content: "";
    display: table; }
  .cf:after,
  .clearfix:after {
    clear: both; }

.left {
  float: left; }

.right {
  float: right; }

.clearfix:after, .clearfix:before {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.pull-right {
  float: right !important; }

.pull-left {
  float: left !important; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.invisible {
  visibility: hidden; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.hidden {
  display: none !important; }

.affix {
  position: fixed; }

input[type=checkbox] {
  position: absolute;
  z-index: -1;
  opacity: 0;
  display: none; }

.control {
  position: relative;
  padding-left: calc(20px + 5px);
  line-height: 20px;
  display: inline-block;
  margin: 5px 0;
  margin-right: 5px; }
  .control__indicator {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background: #ccc; }
    .control__indicator:after {
      content: '';
      position: absolute;
      display: none;
      top: 50%;
      left: 50%; }
  .control--radio .control__indicator {
    border-radius: 50%; }
    .control--radio .control__indicator:after {
      background: white;
      width: 45%;
      height: 45%;
      border-radius: 50%;
      transform: translate(-50%, -50%); }
  .control--checkbox .control__indicator {
    border-radius: 0; }
    .control--checkbox .control__indicator:after {
      width: 65%;
      height: 40%;
      border-width: 3px 3px 0 0;
      border-style: solid;
      border-color: white;
      transform: translate(-50%, -75%) rotate(125deg); }
  .control:hover input ~ .control__indicator,
  .control input:focus ~ .control__indicator {
    background: #bfbfbf; }
  .control input:checked ~ .control__indicator {
    background: blue; }
    .control input:checked ~ .control__indicator:after {
      display: block; }
  .control:hover input:not([disabled]):checked ~ .control__indicator,
  .control input:checked:focus ~ .control__indicator {
    background: #0000e6; }
  .control input:disabled ~ .control__indicator {
    background: #ccc;
    opacity: 0.5;
    pointer-events: none; }

input[type=radio] {
  position: absolute;
  z-index: -1;
  opacity: 0;
  display: none; }

.control {
  position: relative;
  padding-left: calc(20px + 5px);
  line-height: 20px;
  display: inline-block;
  margin: 5px 0;
  margin-right: 5px; }
  .control__indicator {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background: #ccc; }
    .control__indicator:after {
      content: '';
      position: absolute;
      display: none;
      top: 50%;
      left: 50%; }
  .control--radio .control__indicator {
    border-radius: 50%; }
    .control--radio .control__indicator:after {
      background: white;
      width: 45%;
      height: 45%;
      border-radius: 50%;
      transform: translate(-50%, -50%); }
  .control--checkbox .control__indicator {
    border-radius: 2px; }
    .control--checkbox .control__indicator:after {
      width: 65%;
      height: 40%;
      border-width: 3px 3px 0 0;
      border-style: solid;
      border-color: white;
      transform: translate(-50%, -75%) rotate(125deg); }
  .control:hover input ~ .control__indicator,
  .control input:focus ~ .control__indicator {
    background: #bfbfbf; }
  .control input:checked ~ .control__indicator {
    background: blue; }
    .control input:checked ~ .control__indicator:after {
      display: block; }
  .control:hover input:not([disabled]):checked ~ .control__indicator,
  .control input:checked:focus ~ .control__indicator {
    background: #0000e6; }
  .control input:disabled ~ .control__indicator {
    background: #ccc;
    opacity: 0.5;
    pointer-events: none; }

.preloader {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  opacity: 1;
  position: fixed;
  visibility: visible;
  z-index: 100;
  transition: all 0.6s ease-in-out;
  background-color: rgba(0, 0, 0, 0.5); }
  .preloader > div {
    transition: all 0.6s ease-in-out;
    position: relative;
    top: 50%;
    left: 50%;
    margin: -50px;
    width: 100px;
    height: 100px; }
  .preloader.hide-me {
    opacity: 0;
    visibility: hidden; }
    .preloader.hide-me > div {
      transform: scale(0); }

.cssload-loader {
  position: relative;
  top: 50%;
  left: 50%;
  margin: -50px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  perspective: 3300px; }
  .cssload-loader .cssload-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 50%; }
    .cssload-loader .cssload-inner.cssload-one {
      left: 0;
      top: 0;
      animation: cssload-rotate-one 1.1s linear infinite;
      border-bottom: 10px solid #1daeec; }
    .cssload-loader .cssload-inner.cssload-two {
      right: 0;
      top: 0;
      animation: cssload-rotate-two 1.1s linear infinite;
      border-right: 10px solid #424242; }
    .cssload-loader .cssload-inner.cssload-three {
      right: 0;
      bottom: 0;
      animation: cssload-rotate-three 1.1s linear infinite;
      border-top: 10px solid white; }

@keyframes cssload-rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg); }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg); } }

@keyframes cssload-rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg); }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg); } }

@keyframes cssload-rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg); }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg); } }

.system--popup {
  position: absolute;
  top: 0;
  right: 0;
  width: 250px;
  height: 280px;
  z-index: 9999; }
  .system--popup--inner {
    position: absolute;
    left: 0;
    top: 10vh;
    right: 0;
    text-align: center;
    margin: auto;
    background: #f7f8f6;
    padding: 2%;
    overflow: auto;
    box-shadow: 0px 0px 10px #262626;
    color: #262626; }
    .system--popup--inner * {
      color: #262626;
      font-size: 14px;
      display: block;
      margin: 10px 0 10px 0; }
    .system--popup--inner h2 {
      text-align: center;
      font-size: 18px;
      text-transform: uppercase;
      font-family: 'Noto Serif', serif; }

.close-popup {
  font-size: 18px;
  position: absolute;
  top: 0;
  right: 3%;
  cursor: pointer; }

.path-user .login-page {
  max-width: 1366px;
  margin: 0 auto;
  padding: 100px 50px; }
  .path-user .login-page > div {
    display: flex; }
    .path-user .login-page > div > div:first-child {
      width: 450px; }
  .path-user .login-page li {
    margin: 0 0 15px 0; }
    .path-user .login-page li a {
      color: #262626;
      font-size: 14px; }
      @media all and (min-width: 1024px) {
        .path-user .login-page li a {
          font-size: 18px; } }
      .path-user .login-page li a:hover {
        text-decoration: underline; }
  .path-user .login-page label {
    width: 100%;
    display: block;
    color: #999;
    font-size: 14px;
    text-transform: uppercase; }
  .path-user .login-page input {
    width: 180px;
    font-family: 'Open Sans',sans-serif;
    padding: 6px;
    box-shadow: none;
    color: #262626;
    margin: 10px 0;
    border: 1px solid; }
    .path-user .login-page input.form-submit {
      color: #262626;
      font-weight: 700;
      font-size: 18px;
      cursor: pointer;
      background: #fbfbfb; }
  .path-user .login-page #edit-name--description, .path-user .login-page #edit-pass--description {
    display: none; }

.header {
  background: #f7f8f6;
  overflow: hidden;
  padding: 33px 0 0 0;
  overflow: visible; }
  .header--inner {
    max-width: 1366px;
    margin: 0 auto;
    width: 100%;
    padding: 0 5%; }
    .header--inner:before, .header--inner:after {
      content: "";
      clear: both;
      display: block; }
    @media all and (min-width: 1024px) {
      .header--inner {
        padding: 0 45px; } }
    .header--inner--logo {
      float: left;
      position: relative;
      max-width: 50%; }
      .header--inner--logo img {
        width: 100%; }
    .header--inner--nav {
      float: right;
      transition: all 0.5s;
      height: 0px; }
      .header--inner--nav > div:nth-child(2) {
        display: none; }
        .header--inner--nav > div:nth-child(2):before, .header--inner--nav > div:nth-child(2):after {
          clear: both;
          content: '';
          display: block; }
        @media all and (min-width: 1024px) {
          .header--inner--nav > div:nth-child(2) {
            display: block; } }
      .header--inner--nav nav {
        overflow: hidden; }
        .header--inner--nav nav li {
          float: left; }
        .header--inner--nav nav#block-bpc-account-menu {
          margin: 20px auto 50px auto; }
          @media all and (min-width: 1024px) {
            .header--inner--nav nav#block-bpc-account-menu {
              margin: -4px 0 30px 0;
              float: right; } }
          .header--inner--nav nav#block-bpc-account-menu a {
            color: #889a6f;
            background: url("../images/login-ico.png") no-repeat;
            padding: 7px 0 0px 26px;
            line-height: 1.4;
            background-position: 0 50%;
            font-size: 0.8rem;
            height: 29px;
            display: block;
            margin: 0 0 0 32px; }
        .header--inner--nav nav#block-bpc-main-menu {
          float: right;
          clear: both;
          overflow: visible; }
          .header--inner--nav nav#block-bpc-main-menu ul:before, .header--inner--nav nav#block-bpc-main-menu ul:after {
            clear: both;
            content: '';
            display: block; }
          .header--inner--nav nav#block-bpc-main-menu li {
            margin: 0;
            padding: 0 1.2vw;
            position: relative; }
            .header--inner--nav nav#block-bpc-main-menu li:hover {
              padding: 0 1.2vw 47px;
              background: #f7f8f6; }
              .header--inner--nav nav#block-bpc-main-menu li:hover > a {
                color: #b5b9aa; }
            @media all and (min-width: 1024px) {
              .header--inner--nav nav#block-bpc-main-menu li:after {
                content: '';
                display: inline-block;
                width: 1px;
                height: 20px;
                background: #b5b9aa;
                position: absolute;
                right: 0;
                top: 0; } }
            .header--inner--nav nav#block-bpc-main-menu li:last-child {
              margin-right: 0;
              padding-right: 0; }
              .header--inner--nav nav#block-bpc-main-menu li:last-child:after {
                content: '';
                display: none; }
            .header--inner--nav nav#block-bpc-main-menu li ul {
              padding: 0;
              max-width: 90%;
              margin: 30px auto 50px; }
              @media all and (min-width: 1024px) {
                .header--inner--nav nav#block-bpc-main-menu li ul {
                  padding: 30px;
                  display: none;
                  position: absolute;
                  top: 50px;
                  right: 0;
                  background: #fff;
                  border: 1px solid #b5b9aa;
                  border-top: 5px solid #b5b9aa;
                  min-width: 250px;
                  z-index: 9;
                  max-width: 100%;
                  margin: 0; } }
              .header--inner--nav nav#block-bpc-main-menu li ul li {
                border: none;
                padding: 0;
                margin: 0 0 15px 0;
                float: none; }
                .header--inner--nav nav#block-bpc-main-menu li ul li:after {
                  content: '';
                  display: none; }
                .header--inner--nav nav#block-bpc-main-menu li ul li:hover {
                  background: none;
                  padding: 0; }
                .header--inner--nav nav#block-bpc-main-menu li ul li:last-child {
                  border-bottom: none !important; }
                .header--inner--nav nav#block-bpc-main-menu li ul li a {
                  width: 100%;
                  display: block; }
            .header--inner--nav nav#block-bpc-main-menu li:hover ul {
              display: block; }
          .header--inner--nav nav#block-bpc-main-menu a {
            color: #262626;
            font-weight: bold;
            font-size: 1rem;
            border-bottom: 0px solid #262626;
            transition: all .2s ease 0s; }
  .header--border {
    height: 6px;
    width: 100%;
    background: #b5b9aa;
    margin: 12px 0 0 0; }
    .header--border--outer {
      max-width: 1366px;
      margin: 0 auto;
      width: 100%;
      height: 6px;
      overflow: hidden; }
      .header--border--outer--inner {
        width: 690px;
        background: #f7f8f6;
        display: block; }
  .header.open .header--inner--nav {
    min-height: 100vh;
    width: 100%; }
    .header.open .header--inner--nav > div:nth-child(2) {
      display: flex;
      flex-direction: column-reverse; }
    .header.open .header--inner--nav nav#block-bpc-main-menu {
      width: 100%;
      text-align: center;
      margin: 40px 0 0 0; }
      .header.open .header--inner--nav nav#block-bpc-main-menu li {
        border-bottom: 1px solid #b5b9aa;
        border-right: none;
        width: 100%;
        margin: 0;
        padding: 10px 0; }

#nav-icon1 {
  width: 37px;
  height: 23px;
  position: absolute;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  right: 30px;
  top: 43px;
  z-index: 1; }

#nav-icon1 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #262626;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out; }

#nav-icon1 span:nth-child(1) {
  top: 0px; }

#nav-icon1 span:nth-child(2) {
  top: 9px; }

#nav-icon1 span:nth-child(3) {
  top: 19px; }

.open #nav-icon1 span:nth-child(1) {
  top: 9px;
  transform: rotate(135deg);
  width: 100%;
  background: #999999; }

.open #nav-icon1 span:nth-child(2) {
  opacity: 0;
  left: -60px; }

.open #nav-icon1 span:nth-child(3) {
  top: 9px;
  width: 100%;
  transform: rotate(-135deg);
  background: #999999; }

.footer {
  background: #f7f8f6;
  overflow: hidden;
  border-top: 6px solid #b5b9aa;
  padding: 50px; }
  .footer--inner {
    max-width: 1366px;
    margin: 0 auto;
    width: 100%; }
    .footer--inner--nav {
      float: right; }
      .footer--inner--nav li {
        float: left;
        border-right: 1px solid #929a7e;
        margin: 0 15px;
        padding: 3px 30px 4px 0; }
        .footer--inner--nav li:last-child {
          border: none; }
      .footer--inner--nav a {
        color: #929a7e;
        font-size: 1rem; }
    .footer--inner--copy {
      font-size: 1rem;
      font-weight: bold;
      float: left; }

.main .academic-title {
  color: #889a6f;
  font-size: 0.7em; }

.main--slider {
  background: #fff;
  overflow: hidden;
  padding: 0 0 40px 0; }
  .main--slider--inner {
    max-width: 1366px;
    margin: 0 auto;
    width: 100%; }
  .main--slider--arrow--left {
    background: url("../images/left-slider-arrow.png") no-repeat;
    width: 54px;
    height: 106px;
    left: 0px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    display: none; }
    @media all and (min-width: 1024px) {
      .main--slider--arrow--left {
        left: 10px;
        display: block; } }
  .main--slider--arrow--right {
    background: url("../images/right-slider-arrow.png") no-repeat;
    width: 54px;
    height: 106px;
    right: 0px;
    position: absolute;
    top: 0;
    margin: auto;
    bottom: 0;
    cursor: pointer;
    display: none; }
    @media all and (min-width: 1024px) {
      .main--slider--arrow--right {
        right: 10px;
        display: block; } }

#slider {
  position: relative;
  padding: 50px 0% 0 0%;
  margin: 0 auto;
  width: 100%;
  overflow: hidden; }
  @media all and (min-width: 1024px) {
    #slider {
      padding: 49px 7% 0 7%;
      width: 100%;
      float: left;
      overflow: initial; } }
  #slider > div {
    float: left; }
  #slider .first-elem-box {
    opacity: 1;
    transition: opacity 0.25s; }
  #slider .hide-slide .first-elem-box {
    opacity: 0; }
  #slider #first-elem-slider {
    width: 100%;
    border: 4px solid #b5b9aa;
    padding: 2px; }
    @media all and (min-width: 1024px) {
      #slider #first-elem-slider {
        width: 50%;
        padding: 2%; } }
    #slider #first-elem-slider .first-elem-box {
      transition-delay: 0; }
    #slider #first-elem-slider .content {
      float: right;
      width: 100%;
      padding: 5%;
      text-align: center; }
      @media all and (min-width: 1024px) {
        #slider #first-elem-slider .content {
          text-align: left;
          width: 50%;
          padding: 0 0 0 3%; } }
      #slider #first-elem-slider .content p {
        color: #999999; }
      #slider #first-elem-slider .content a {
        color: #262626;
        margin: 10px 0 0 0;
        display: block;
        text-align: right; }
        #slider #first-elem-slider .content a:hover {
          color: #889a6f; }
    #slider #first-elem-slider .image {
      float: left;
      width: 100%;
      text-align: center; }
      #slider #first-elem-slider .image img {
        width: 100%;
        max-width: 238px; }
      @media all and (min-width: 1024px) {
        #slider #first-elem-slider .image {
          text-align: left;
          width: 50%; } }
  #slider #second-elem-slider {
    width: 25%;
    text-align: center;
    padding: 49px 0;
    border-right: 1px solid #ebebeb;
    display: none; }
    @media all and (min-width: 1024px) {
      #slider #second-elem-slider {
        display: block; } }
    #slider #second-elem-slider .first-elem-box {
      transition-delay: 0.1s; }
    #slider #second-elem-slider img {
      max-width: 100%;
      width: 80%; }
  #slider #last-elem-slider {
    text-align: center;
    width: 25%;
    padding: 49px 0;
    display: none; }
    @media all and (min-width: 1024px) {
      #slider #last-elem-slider {
        display: block; } }
    #slider #last-elem-slider .first-elem-box {
      transition-delay: 0.2s; }
    #slider #last-elem-slider img {
      max-width: 100%;
      width: 80%; }

.slick-slide:not(.slick-current) {
  visibility: hidden; }

.slick-slider {
  margin-bottom: 0;
  position: relative; }

ul.slick-slide {
  padding-left: 0;
  max-width: 100%; }

.slick-slider .slick-track {
  min-width: 100%; }

ul.slick-slide li img, .slick-dots button img {
  width: 100%;
  height: auto; }

.slick-dots {
  bottom: 0;
  top: auto;
  left: auto;
  right: auto;
  position: relative;
  line-height: 0;
  width: calc(100% - 108px);
  margin: 0 50px 0 58px; }

.slick-dots li, .slick-dots li button:before {
  /* width: 100px;
    height:100px; */
  margin: 0;
  padding: 0; }

.slick-dots li {
  margin: 5px;
  border: 1px solid #ccc;
  display: inline-block;
  width: auto;
  height: auto; }

.slick-slider .slick-track {
  min-width: 100%; }

.slick-dots li button {
  margin: 0;
  height: auto;
  width: 100%;
  padding: 0; }

.slick-dots li button.tab {
  clear: both;
  display: block; }

.slick-dots li button img {
  width: 100%;
  max-width: 80px;
  height: auto; }

.slick-dots li button:before {
  content: '';
  border: 1px solid #f00;
  display: block;
  width: 100%;
  max-width: 150px;
  overflow: hidden;
  float: none;
  position: absolute;
  width: 100%;
  height: 100%; }

.slick-thumbs {
  position: absolute;
  left: -9999px; }

.slick-next, .slick-prev {
  width: 54px;
  height: 106px; }

.slick-next {
  background: url("../images/right-slider-arrow.png") no-repeat; }
  .slick-next:focus, .slick-next:hover {
    background: url("../images/right-slider-arrow.png") no-repeat; }

.slick-prev {
  background: url("../images/left-slider-arrow.png") no-repeat; }
  .slick-prev:focus, .slick-prev:hover {
    background: url("../images/left-slider-arrow.png") no-repeat; }

.slick-list {
  margin: 0 3% 0 3%; }
  @media all and (min-width: 1024px) {
    .slick-list {
      margin: 0 50px 0 58px; } }

.slick-dots li button:before {
  border: 2px solid transparent; }

.slick-dots li.slick-active button:before {
  border: 2px solid #f7f8f6; }

/*font-family: 'Noto Serif', serif;*/
.main--news {
  background: #fbfbfb;
  overflow: hidden; }
  .main--news--inner {
    max-width: 1366px;
    margin: 0 auto;
    width: 100%;
    padding: 40px 0;
    overflow: hidden;
    display: flex;
    flex-direction: column; }
    @media all and (min-width: 1024px) {
      .main--news--inner {
        flex-direction: row;
        padding: 90px 0; } }
    .main--news--inner--left-col {
      padding: 0 5%; }
      @media all and (min-width: 1024px) {
        .main--news--inner--left-col {
          min-width: 50%;
          padding: 0 50px;
          flex-grow: 1; } }
    .main--news--inner--right-col {
      padding: 0 5%; }
      @media all and (min-width: 1024px) {
        .main--news--inner--right-col {
          padding: 0 50px;
          min-width: 50%;
          flex-grow: 1; } }
      @media all and (min-width: 1440px) {
        .main--news--inner--right-col {
          padding: 0 0 0 50px; } }
  .main--news--category--read-more {
    width: 100%;
    border: 4px solid #b5b9aa;
    display: block;
    padding: 20px 20px;
    text-align: center;
    color: #262626;
    font-size: 0.8rem; }
    .main--news--category--read-more:hover {
      color: #889a6f; }
    @media all and (min-width: 1024px) {
      .main--news--category--read-more {
        font-size: 1rem; } }
    @media all and (max-width: 1024px) {
      .main--news--category--read-more {
        margin-bottom: 50px; } }

.main--news--inner--left-col ~ .main--news--inner--right-col {
  border-left: 1px solid #ebebeb; }

.title-news {
  font-family: 'Noto Serif', serif;
  color: #262626; }
  .title-news span {
    display: block; }
    .title-news span:first-child {
      font-size: 1.5rem;
      line-height: 1; }
      @media all and (min-width: 1024px) {
        .title-news span:first-child {
          font-size: 2rem; } }
    .title-news span:last-child {
      font-size: 2rem; }
      @media all and (min-width: 1024px) {
        .title-news span:last-child {
          font-size: 2.5rem; } }
  .title-news--border {
    width: 100%;
    border-bottom: 5px solid #889a6f;
    margin: 0 0 8px 27px;
    flex: 1; }
    @media all and (min-width: 1024px) {
      .title-news--border {
        margin: 0 0 14px 27px; } }
  .title-news--box {
    display: flex;
    flex-direction: row;
    margin: 0 0 34px 0; }
    @media all and (min-width: 1024px) {
      .title-news--box {
        margin: 0 0 64px 0; } }

.events {
  position: relative; }
  @media all and (min-width: 1024px) {
    .events {
      margin: 0 0 0 -12px; } }
  .events--single-event {
    overflow: hidden;
    margin: 0 0 50px 0;
    display: flex;
    flex-direction: row; }
    @media all and (min-width: 1024px) {
      .events--single-event {
        margin: 0 0 90px 0; } }
    .events--single-event--date-box {
      margin: 0px 20px 0 0; }
      .events--single-event--date-box--top {
        width: 100%;
        border: 1px solid rgba(153, 153, 153, 0.2);
        text-align: center;
        color: #999;
        padding: 10px 15px 10px; }
        @media all and (min-width: 1024px) {
          .events--single-event--date-box--top {
            padding: 25px 30px 19px 30px; } }
        .events--single-event--date-box--top span {
          display: block;
          font-size: 1.5rem;
          font-weight: bold;
          margin: 0px 0 4px 0;
          line-height: 1; }
          @media all and (min-width: 1024px) {
            .events--single-event--date-box--top span {
              font-size: 2.5rem; } }
          .events--single-event--date-box--top span:first-child {
            font-size: 1rem;
            margin: 0; }
            @media all and (min-width: 1024px) {
              .events--single-event--date-box--top span:first-child {
                font-size: 1.5rem; } }
          .events--single-event--date-box--top span:last-child {
            font-size: 0.8rem;
            margin: 0; }
            @media all and (min-width: 1024px) {
              .events--single-event--date-box--top span:last-child {
                font-size: 1.5rem; } }
      .events--single-event--date-box--bottom {
        width: 100%;
        border: 1px solid rgba(153, 153, 153, 0.2);
        text-align: center;
        color: #999;
        padding: 10px 0;
        font-size: 1rem;
        font-weight: bold;
        border-top: none; }
    .events--single-event--content-box {
      flex: 1; }
      .events--single-event--content-box h3 a {
        color: #262626;
        font-weight: bold;
        font-size: 1rem;
        margin: 0 0 10px 0;
        font-family: 'Noto Serif', serif; }
        @media all and (min-width: 1024px) {
          .events--single-event--content-box h3 a {
            font-size: 1.5rem; } }
      .events--single-event--content-box--description {
        color: #999999;
        font-size: 0.8rem; }
        @media all and (min-width: 1024px) {
          .events--single-event--content-box--description {
            font-size: 1rem; } }
      .events--single-event--content-box--date {
        color: #999999;
        display: block;
        font-size: 0.8rem;
        margin: 17px 0 0 0; }
      .events--single-event--content-box--link {
        display: block;
        margin: 10px 0; }
        @media all and (min-width: 1024px) {
          .events--single-event--content-box--link {
            text-align: right;
            margin: 0; } }
        .events--single-event--content-box--link a {
          color: #262626;
          font-size: 0.8rem; }
          .events--single-event--content-box--link a:hover {
            color: #889a6f; }
          @media all and (min-width: 1024px) {
            .events--single-event--content-box--link a {
              font-size: 1rem; } }

.blog-news {
  width: 100%; }
  @media all and (min-width: 1024px) {
    .blog-news {
      padding: 11px 0 0 0;
      width: 92%; } }
  .blog-news--single {
    overflow: hidden;
    margin: 0 0 72px 0;
    display: flex;
    flex-direction: row; }
  .blog-news--photo {
    width: 100px;
    margin: 0 20px 0px 0;
    float: left; }
    @media all and (min-width: 1024px) {
      .blog-news--photo {
        width: 147px;
        min-width: 147px; } }
    .blog-news--photo img {
      padding: 8px;
      border: 1px solid rgba(153, 153, 153, 0.2);
      display: block;
      margin: 0 auto;
      width: 100%; }
  .blog-news--content {
    flex: 1; }
    .blog-news--content--name {
      font-weight: bold;
      color: #999999;
      font-size: 0.8rem; }
      @media all and (min-width: 1024px) {
        .blog-news--content--name {
          font-size: 1rem; } }
    .blog-news--content--date {
      color: #999999;
      font-size: 0.6rem;
      float: right; }
      @media all and (min-width: 1024px) {
        .blog-news--content--date {
          font-size: 0.6rem; } }
    .blog-news--content--title {
      font-family: 'Noto Serif', serif;
      display: block;
      margin: 8px 0 13px 0;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.2; }
      @media all and (min-width: 1024px) {
        .blog-news--content--title {
          font-size: 2rem; } }
    .blog-news--content--description {
      color: #999999;
      font-size: 0.8rem; }
      @media all and (min-width: 1024px) {
        .blog-news--content--description {
          font-size: 1rem; } }
      .blog-news--content--description--link a {
        color: #262626;
        font-size: 0.8rem; }
        @media all and (min-width: 1024px) {
          .blog-news--content--description--link a {
            font-size: 1rem; } }

.news-page {
  margin: 60px 0; }
  .news-page--inner {
    display: flex;
    flex-direction: row;
    width: 100%; }
    .news-page--inner--left-container {
      width: 62.6%; }
      .news-page--inner--left-container--inner {
        max-width: 924px;
        width: 100%;
        float: right;
        padding: 0 119px 0 0; }
    .news-page--inner--right-container {
      flex: 1;
      position: relative;
      background: #fbfbfb; }
      .news-page--inner--right-container--inner {
        max-width: 449px;
        float: left;
        padding: 56px 0 65px 56px; }
        .news-page--inner--right-container--inner--title {
          font-size: 1.5rem;
          font-family: 'Noto Serif',serif;
          border-bottom: 5px solid #b5b9aa;
          width: 100%;
          display: block;
          padding-bottom: 18px;
          margin: 0 0 35px 0; }
    .news-page--inner--author {
      color: #6483bd;
      font-weight: bold;
      font-size: 1.5rem;
      margin: 0 0 18px 58px;
      display: block; }
    .news-page--inner--title {
      padding: 37px 0 34px 58px;
      font-size: 3rem;
      font-family: 'Noto Serif', serif;
      display: inline;
      width: 74%;
      float: right;
      margin: 0 0 60px 0; }
    .news-page--inner--content {
      clear: both;
      overflow: hidden;
      margin-top: 50px; }
  .news-page .events--single-event--date-box {
    width: 26%;
    float: left;
    margin: 11px 0 0;
    display: inline-block; }
  .news-page p {
    margin: 0 0 30px;
    font-size: 1rem;
    color: #777; }
  .news-page ul {
    margin: 0 0 60px 0; }
    .news-page ul li {
      padding: 0 0 0 10px;
      display: inline-flex;
      color: #777; }
      .news-page ul li:before {
        content: "\002022";
        color: #afafaf;
        padding: 0 10px 0 0px; }
  .news-page h1 {
    margin: 20px 0 25px 0;
    font-size: 2rem;
    font-family: 'Noto Serif', serif;
    color: #262626;
    font-weight: bold; }
  .news-page h2 {
    margin: 60px 0 20px 0;
    font-size: 1.5rem;
    font-family: 'Noto Serif', serif;
    color: #262626;
    font-weight: bold; }
  .news-page h3 {
    margin: 20px 0 45px 0;
    font-size: 1rem;
    font-family: 'Noto Serif', serif;
    font-weight: bold;
    color: #262626; }
  .news-page .text-align-center {
    text-align: center; }
  .news-page .text-align-left {
    text-align: left; }
  .news-page .text-align-right {
    text-align: right; }
  .news-page img[data-align=center] {
    text-align: center;
    margin: 50px auto;
    display: block;
    border: 1px solid #f7f8f6;
    padding: 20px; }
  .news-page img[data-align=right] {
    text-align: right;
    float: right;
    margin: 50px 0 50px 20px;
    border: 1px solid #f7f8f6;
    padding: 20px; }
  .news-page img[data-align=left] {
    text-align: left;
    float: left;
    margin: 50px 20px 50px 0;
    border: 1px solid #f7f8f6;
    padding: 20px; }

.text-page {
  margin: 60px 0;
  font-family: 'Noto Serif',serif; }
  .text-page * {
    font-family: 'Noto Serif',serif;
    color: #777; }
  .text-page--inner {
    display: flex;
    flex-direction: column-reverse;
    width: 100%; }
    @media all and (min-width: 1024px) {
      .text-page--inner {
        flex-direction: row; } }
    .text-page--inner--left-container {
      width: 100%; }
      @media all and (min-width: 1024px) {
        .text-page--inner--left-container {
          width: 62.6%; } }
      .text-page--inner--left-container--inner {
        float: right;
        padding: 50px 3%;
        width: 100%; }
        @media all and (min-width: 1024px) {
          .text-page--inner--left-container--inner {
            padding: 103px 83px 0 0;
            max-width: 871px; } }
    .text-page--inner--right-container {
      flex: 1;
      position: relative;
      background: #fbfbfb; }
      .text-page--inner--right-container--inner {
        max-width: 442px;
        float: left; }
      .text-page--inner--right-container a {
        font-family: 'Noto Serif', serif;
        font-size: 1rem;
        color: #262626; }
        .text-page--inner--right-container a:hover {
          color: #889a6f; }
      .text-page--inner--right-container .unformatted-list--inner--right-container--filter-box {
        margin-bottom: 1em; }
      .text-page--inner--right-container .unformatted-list--inner--right-container--inner {
        padding: 106px 0 65px 86px; }
    .text-page--inner--author {
      margin: 0 3% 18px 3%;
      display: block; }
      @media all and (min-width: 1024px) {
        .text-page--inner--author {
          margin: 0 0 18px 51px; } }
      .text-page--inner--author a {
        color: #999999;
        font-weight: bold;
        font-size: 1rem; }
        .text-page--inner--author a:hover {
          color: #6483bd; }
        @media all and (min-width: 1024px) {
          .text-page--inner--author a {
            font-size: 1.5rem; } }
    .text-page--inner--title {
      border-top: 1px solid rgba(153, 153, 153, 0.2);
      border-bottom: 1px solid rgba(153, 153, 153, 0.2);
      padding: 16px 3% 16px 3%;
      margin: 0 0 30px;
      line-height: 1.25; }
      @media all and (min-width: 1024px) {
        .text-page--inner--title {
          margin: 0 0 60px;
          padding: 36px 0 36px 50px; } }
      .text-page--inner--title a {
        font-family: 'Noto Serif', serif;
        font-size: 2rem;
        color: #262626; }
        .text-page--inner--title a:hover {
          color: #889a6f; }
        @media all and (min-width: 1024px) {
          .text-page--inner--title a {
            font-size: 3rem; } }
    .text-page--inner--content {
      padding: 0 3%; }
      @media all and (min-width: 1024px) {
        .text-page--inner--content {
          padding: 0 0 0 50px; } }
    .text-page--inner i {
      font-style: italic; }
    .text-page--inner h2 {
      font-size: 1.5rem;
      font-weight: 700;
      color: #262626; }
      @media all and (min-width: 1024px) {
        .text-page--inner h2 {
          font-size: 1.87rem; } }
    .text-page--inner h3 {
      font-size: 1.2rem;
      font-weight: 700;
      margin: 15px 0 0 0;
      color: #262626; }
      @media all and (min-width: 1024px) {
        .text-page--inner h3 {
          font-size: 1.52rem; } }
    .text-page--inner h4 {
      margin: 17px 0 0 0;
      font-size: 1rem;
      font-weight: 700;
      color: #262626; }
      @media all and (min-width: 1024px) {
        .text-page--inner h4 {
          font-size: 1.14rem; } }
    .text-page--inner p {
      line-height: 1.45;
      color: #777;
      margin: 20px 0 0 0;
      font-size: 0.9rem; }
      .text-page--inner p + h4 {
        margin: 55px 0 0 0; }
        @media all and (min-width: 1024px) {
          .text-page--inner p + h4 {
            margin: 85px 0 0 0; } }
      .text-page--inner p + p {
        margin: 21px 0 0 0; }
      @media all and (min-width: 1024px) {
        .text-page--inner p {
          margin: 40px 0 0 0;
          font-size: 1.07rem; } }
    .text-page--inner ul {
      font-size: 0.95rem;
      line-height: 1.2;
      display: block;
      color: #777;
      padding: 0;
      margin: 30px 0 50px; }
      @media all and (min-width: 1024px) {
        .text-page--inner ul {
          margin: 30px 0 80px 0;
          padding: 0 0 0 99px; } }
    .text-page--inner img {
      padding: 2.4%;
      width: 100%;
      margin: 45px 0 13px 0;
      border: 1px solid #ebebeb; }
      @media all and (min-width: 1024px) {
        .text-page--inner img {
          width: 105%;
          margin: 45px 0 13px -19px; } }
    .text-page--inner blockquote {
      display: none;
      padding: 5% 10% 5% 5%;
      background: #fbfbfb;
      margin: 15px 0;
      position: relative;
      width: 100%; }
      .text-page--inner blockquote span {
        position: absolute;
        top: 5%;
        right: 1.3%;
        text-indent: -999999px;
        background: url("../images/square-close-button.png") 0 0 no-repeat;
        width: 25px;
        height: 25px;
        cursor: pointer; }
      .text-page--inner blockquote.open {
        display: block; }
    .text-page--inner q {
      margin: 0 5px 0 -3px;
      color: #6483bd;
      cursor: pointer;
      font-size: 70%;
      vertical-align: top;
      color: #6483bd; }
    .text-page--inner .cite-box {
      position: absolute;
      left: 20px;
      display: none; }
      @media all and (min-width: 1024px) {
        .text-page--inner .cite-box {
          display: block; } }
      .text-page--inner .cite-box span {
        color: #777;
        font-size: 1rem;
        margin: 0 16px 0 0; }
      .text-page--inner .cite-box button {
        background: none;
        border: 1px solid #b5b9aa;
        color: #b5b9aa;
        font-weight: 700;
        font-size: 1rem;
        padding: 4px 29px;
        cursor: pointer; }
        .text-page--inner .cite-box button:hover {
          border: 1px solid #6483bd;
          color: #6483bd; }

.bpc-popup.cite .bpc-popup--inner {
  background: #fbfbfb;
  width: 50vw;
  height: 55vh;
  padding: 20px; }
  .bpc-popup.cite .bpc-popup--inner * {
    color: #262626; }
  .bpc-popup.cite .bpc-popup--inner .bpc-popup--inner--close {
    background: url("../images/square-close-button.png") 0 0 no-repeat;
    top: 10px;
    right: 10px; }

.single-element {
  margin: 20px 0; }
  @media all and (min-width: 1024px) {
    .single-element {
      margin: 40px 0; } }
  .single-element--inner {
    display: flex;
    flex-direction: column-reverse;
    width: 100%; }
    @media all and (min-width: 1024px) {
      .single-element--inner {
        flex-direction: row; } }
    .single-element--inner.news {
      flex-direction: column; }
    .single-element--inner--description {
      margin: 36px 0 0 0;
      color: #999; }
      @media all and (min-width: 1024px) {
        .single-element--inner--description {
          max-width: calc(100% - 209px); } }
    .single-element--inner--left-container {
      width: 100%; }
      @media all and (min-width: 1024px) {
        .single-element--inner--left-container {
          width: 62.6%; } }
      .single-element--inner--left-container--inner {
        float: right;
        padding: 30px 3% 0;
        width: 100%; }
        @media all and (min-width: 1024px) {
          .single-element--inner--left-container--inner {
            padding: 103px 29px 0 50px;
            max-width: 924px; } }
        .single-element--inner--left-container--inner.material {
          min-width: 80%; }
    .single-element--inner--right-container {
      flex: 1;
      position: relative;
      background: #fbfbfb; }
      .single-element--inner--right-container--inner {
        max-width: 100%;
        float: left;
        padding: 7vh 5vw 20px; }
    .single-element--inner--author {
      margin: 0px 0 18px 3%;
      display: block; }
      @media all and (min-width: 1024px) {
        .single-element--inner--author {
          margin: 0 0 18px 58px; } }
      .single-element--inner--author a {
        color: #999999;
        font-weight: bold;
        font-size: 1rem; }
        @media all and (min-width: 1024px) {
          .single-element--inner--author a {
            font-size: 1.5rem; } }
    .single-element--inner--title {
      border-top: 1px solid rgba(153, 153, 153, 0.2);
      padding: 29px 3% 32px 3%;
      font-size: 2rem;
      font-family: 'Noto Serif', serif;
      border-bottom: 1px solid rgba(153, 153, 153, 0.2);
      margin: 0 0 26px 0;
      color: #262626;
      display: flex;
      flex-direction: column;
      align-items: center; }
      @media all and (min-width: 486px) {
        .single-element--inner--title {
          align-items: initial;
          flex-direction: row; } }
      @media all and (min-width: 1024px) {
        .single-element--inner--title {
          margin: 0 0 66px 0;
          display: block;
          font-size: 2.5rem;
          padding: 29px 0 32px 58px; } }
      .single-element--inner--title.single-event {
        display: flex; }
      .single-element--inner--title--image {
        margin: 0 2vw 0 0;
        flex-grow: 1;
        height: 223px; }
        @media all and (min-width: 486px) {
          .single-element--inner--title--image {
            min-width: 33%;
            max-width: 33%;
            height: initial; } }
      .single-element--inner--title--titles {
        flex-grow: 1;
        min-width: 67%;
        word-wrap: break-word; }
    .single-element--inner--subtitle {
      padding: 9px 0 0 0;
      font-size: 1rem;
      font-family: 'Noto Serif', serif;
      color: #262626; }
      @media all and (min-width: 1024px) {
        .single-element--inner--subtitle {
          font-size: 1.5rem; } }
    .single-element--inner--content {
      padding: 0 3%;
      color: #777; }
      @media all and (min-width: 1024px) {
        .single-element--inner--content {
          padding: 0 58px 0 58px; } }
      .single-element--inner--content article.embedded-entity {
        width: auto;
        height: auto; }
      .single-element--inner--content article.embedded-entity img {
        max-width: 100%;
        max-height: 100%;
        height: auto; }
      .single-element--inner--content--section {
        padding: 20px 0 0 0;
        color: #777; }
        @media all and (min-width: 1024px) {
          .single-element--inner--content--section {
            padding: 110px 0 0 0; } }
        .single-element--inner--content--section.no-padding {
          padding: 0; }
        .single-element--inner--content--section > div > div:first-child {
          font-size: 1.5rem;
          color: #262626;
          font-weight: bold;
          font-family: 'Noto Serif', serif;
          padding: 0 3% 10px;
          margin: 0 0 25px 0;
          border-bottom: 5px solid #b5b9aa; }
          @media all and (min-width: 1024px) {
            .single-element--inner--content--section > div > div:first-child {
              padding: 0 0 9px 0;
              margin: 0 50px 45px 58px;
              font-size: 2rem; } }
        .single-element--inner--content--section > div div > ol {
          margin: 0 50px 0 0; }
        .single-element--inner--content--section div > p {
          padding: 0 3%; }
          @media all and (min-width: 1024px) {
            .single-element--inner--content--section div > p {
              padding: 0 58px; } }
        .single-element--inner--content--section h4:first-child {
          margin-top: 0; }
        .single-element--inner--content--section h4 {
          font-size: 1.5rem;
          color: #262626;
          font-weight: bold;
          font-family: 'Noto Serif', serif;
          padding: 0 3% 9px;
          margin: 45px 0 45px 0;
          border-bottom: 5px solid #b5b9aa; }
          @media all and (min-width: 1024px) {
            .single-element--inner--content--section h4 {
              font-size: 2rem;
              padding: 0 0 9px 0;
              margin: 45px 50px 45px 58px; } }
        .single-element--inner--content--section .video-container {
          margin: 0 3% 0 3%;
          width: 94%; }
          @media all and (min-width: 1024px) {
            .single-element--inner--content--section .video-container {
              width: calc(100% - 108px);
              margin: 0 50px 0 58px; } }
          .single-element--inner--content--section .video-container li {
            padding: 0 0 20px 0; }
        .single-element--inner--content--section .documents {
          margin-bottom: 3em; }
    .single-element--inner--img-link {
      margin: 0 38px 0 0;
      min-width: 171px; }
    .single-element--inner--image img {
      height: 223px;
      width: auto; }
      @media all and (min-width: 486px) {
        .single-element--inner--image img {
          height: initial;
          width: 100%; } }
    .single-element--inner--date-box {
      margin: 0px 20px 0 0; }
      .single-element--inner--date-box--top {
        width: 100%;
        border: 1px solid rgba(153, 153, 153, 0.2);
        text-align: center;
        color: #999;
        padding: 15px 20px 9px 20px; }
        @media all and (min-width: 1024px) {
          .single-element--inner--date-box--top {
            padding: 10px 15px 10px; } }
        .single-element--inner--date-box--top span {
          display: block;
          font-size: 1.5rem;
          font-weight: bold;
          margin: 0px 0 4px 0;
          line-height: 1; }
          @media all and (min-width: 1024px) {
            .single-element--inner--date-box--top span {
              font-size: 2.5rem; } }
          .single-element--inner--date-box--top span:first-child {
            font-size: 1rem;
            margin: 0; }
            @media all and (min-width: 1024px) {
              .single-element--inner--date-box--top span:first-child {
                font-size: 1.5rem; } }
          .single-element--inner--date-box--top span:last-child {
            font-size: 0.8rem;
            margin: 0; }
            @media all and (min-width: 1024px) {
              .single-element--inner--date-box--top span:last-child {
                font-size: 1.5rem; } }
      .single-element--inner--date-box--bottom {
        width: 100%;
        border: 1px solid rgba(153, 153, 153, 0.2);
        text-align: center;
        color: #999;
        padding: 10px;
        font-size: 1rem;
        font-weight: bold;
        border-top: none; }
  .single-element ol {
    overflow: hidden;
    width: 100%; }
    .single-element ol.video-container li {
      padding: 0 0 20px 0; }
    .single-element ol li {
      border-bottom: 1px solid rgba(153, 153, 153, 0.2);
      padding: 0 7% 11px 3%;
      margin: 0 0 12px;
      font-size: 1rem;
      position: relative; }
      @media all and (min-width: 1024px) {
        .single-element ol li {
          padding: 0 10% 11px 58px;
          margin: 0 0 12px 0; } }
      .single-element ol li .arrow {
        background: url("../images/square-arrow-bottom-grey.png") 0 0 no-repeat;
        background-position: 94% 50%;
        cursor: pointer;
        display: block;
        width: 25px;
        height: 25px;
        position: absolute;
        top: 0;
        right: 0; }
      .single-element ol li.expand {
        background: none;
        display: block;
        position: relative;
        padding: 0 0% 11px 3%; }
        @media all and (min-width: 1024px) {
          .single-element ol li.expand {
            padding: 0 0 11px 58px; } }
        .single-element ol li.expand > .arrow {
          background: url("../images/square-arrow-top-grey.png") 0 0 no-repeat; }
      .single-element ol li a {
        color: #777; }
        .single-element ol li a:hover {
          color: #889a6f; }
      .single-element ol li h5 {
        margin: 0 0 20px 0;
        font-size: 1.5rem;
        color: #262626;
        font-style: italic;
        font-weight: 700;
        font-family: 'Noto Serif',serif; }
    .single-element ol ol {
      max-height: 0;
      overflow: hidden; }
      .single-element ol ol li {
        font-size: 1rem; }
        .single-element ol ol li:last-child {
          border: none; }
      .single-element ol ol ol li {
        font-size: 1rem; }
        .single-element ol ol ol li:last-child {
          border: none; }
      .single-element ol ol ol ol li {
        font-size: 0.8rem; }
        .single-element ol ol ol ol li:last-child {
          border: none; }
  .single-element .link--download {
    text-align: center;
    display: block;
    margin: 0;
    border: 1px solid #b5b9aa;
    padding: 15px;
    font-size: 0.8rem;
    color: #b5b9aa;
    font-weight: bold; }
    @media all and (min-width: 1024px) {
      .single-element .link--download {
        margin: 42px 0 0 0;
        font-size: 1rem; } }
  .single-element .button-expand-all {
    float: right;
    border: 1px solid #b5b9aa;
    padding: 5px 15px;
    cursor: pointer;
    margin: 0 0 30px 0;
    transition: all 0.3s; }
    .single-element .button-expand-all:hover {
      background: #b5b9aa;
      color: #262626; }

.unformatted-list {
  margin: 60px 0; }
  .unformatted-list.no-margin-top {
    margin: 0 3% 60px 3%; }
    @media all and (min-width: 1450px) {
      .unformatted-list.no-margin-top {
        margin: 0 0 60px 0; } }
  .unformatted-list.no-margin-bottom {
    margin: 60px 3% 0 3%; }
    @media all and (min-width: 1450px) {
      .unformatted-list.no-margin-bottom {
        margin: 60px 0 0 0; } }
  .unformatted-list.border-bottom {
    border-bottom: 1px solid #ebebeb; }
  .unformatted-list--inner {
    display: flex;
    flex-direction: column-reverse;
    width: 100%; }
    @media all and (min-width: 1024px) {
      .unformatted-list--inner {
        flex-direction: row; } }
    .unformatted-list--inner--left-container {
      width: 100%; }
      @media all and (min-width: 1024px) {
        .unformatted-list--inner--left-container {
          width: 62.6%; } }
      .unformatted-list--inner--left-container--inner {
        padding: 40px 3% 0 3%; }
        @media all and (min-width: 1024px) {
          .unformatted-list--inner--left-container--inner {
            max-width: 871px;
            float: right;
            padding: 40px 119px 0 0;
            min-width: 73%; } }
        .unformatted-list--inner--left-container--inner .unformatted-row {
          border-bottom: 1px solid #ebebeb; }
          .unformatted-list--inner--left-container--inner .unformatted-row:last-child {
            border: none; }
          .unformatted-list--inner--left-container--inner .unformatted-row .single-element--inner {
            flex-direction: column;
            padding: 0 3%; }
            @media all and (min-width: 486px) {
              .unformatted-list--inner--left-container--inner .unformatted-row .single-element--inner {
                flex-direction: row; } }
            .unformatted-list--inner--left-container--inner .unformatted-row .single-element--inner.news {
              flex-direction: column; }
              .unformatted-list--inner--left-container--inner .unformatted-row .single-element--inner.news .events {
                margin: 0; }
            .unformatted-list--inner--left-container--inner .unformatted-row .single-element--inner.material {
              flex-direction: column; }
              .unformatted-list--inner--left-container--inner .unformatted-row .single-element--inner.material h3 a {
                font-size: 1rem; }
                @media all and (min-width: 1024px) {
                  .unformatted-list--inner--left-container--inner .unformatted-row .single-element--inner.material h3 a {
                    font-size: 2.5rem; } }
            .unformatted-list--inner--left-container--inner .unformatted-row .single-element--inner.two-sides {
              flex-direction: row;
              padding: 30px 0; }
              .unformatted-list--inner--left-container--inner .unformatted-row .single-element--inner.two-sides .single-element--inner--left {
                margin: 0 5% 0 0; }
                @media all and (min-width: 1024px) {
                  .unformatted-list--inner--left-container--inner .unformatted-row .single-element--inner.two-sides .single-element--inner--left {
                    margin: 0; } }
              .unformatted-list--inner--left-container--inner .unformatted-row .single-element--inner.two-sides .single-element--inner--right {
                width: 100%; }
    .unformatted-list--inner--right-container {
      flex: 1;
      position: relative;
      background: #fbfbfb; }
      .unformatted-list--inner--right-container--inner {
        width: 100%;
        padding: 3%; }
        @media all and (min-width: 1024px) {
          .unformatted-list--inner--right-container--inner {
            width: 449px;
            float: left;
            padding: 106px 50px 65px 56px; } }
        @media all and (min-width: 1250px) {
          .unformatted-list--inner--right-container--inner {
            padding: 106px 0 65px 56px; } }
      .unformatted-list--inner--right-container--title {
        font-size: 4.5vw;
        font-family: 'Noto Serif',serif;
        border-bottom: 5px solid #b5b9aa;
        width: 100%;
        display: block;
        padding-bottom: 18px;
        margin: 0 0 35px 0; }
        @media all and (min-width: 486px) {
          .unformatted-list--inner--right-container--title {
            font-size: 1.5rem; } }
      .unformatted-list--inner--right-container--filter-box {
        margin-bottom: 3em; }
    .unformatted-list--inner--full-container {
      width: 100%; }
      @media all and (min-width: 1024px) {
        .unformatted-list--inner--full-container {
          width: 100%;
          max-width: 1366px;
          margin: 0 auto; } }
      .unformatted-list--inner--full-container--inner {
        padding: 0 3% 0 3%; }
        @media all and (min-width: 1024px) {
          .unformatted-list--inner--full-container--inner {
            padding: 40px 0 0 0; } }
        .unformatted-list--inner--full-container--inner .unformatted-row {
          border-bottom: 1px solid #ebebeb; }
          .unformatted-list--inner--full-container--inner .unformatted-row:last-child {
            border: none; }
          .unformatted-list--inner--full-container--inner .unformatted-row .single-element--inner {
            flex-direction: column;
            padding: 0 3%; }
            @media all and (min-width: 1024px) {
              .unformatted-list--inner--full-container--inner .unformatted-row .single-element--inner {
                flex-direction: row; } }
            .unformatted-list--inner--full-container--inner .unformatted-row .single-element--inner.material {
              flex-direction: column; }
              .unformatted-list--inner--full-container--inner .unformatted-row .single-element--inner.material h3 a {
                font-size: 1rem; }
                @media all and (min-width: 1024px) {
                  .unformatted-list--inner--full-container--inner .unformatted-row .single-element--inner.material h3 a {
                    font-size: 2.5rem; } }
            .unformatted-list--inner--full-container--inner .unformatted-row .single-element--inner.two-sides {
              flex-direction: row;
              padding: 30px 0; }
              .unformatted-list--inner--full-container--inner .unformatted-row .single-element--inner.two-sides .single-element--inner--left {
                margin: 0 5% 0 0; }
              .unformatted-list--inner--full-container--inner .unformatted-row .single-element--inner.two-sides .single-element--inner--right {
                width: 100%; }
    .unformatted-list--inner--author {
      margin: 0 0 18px 58px;
      display: block; }
      .unformatted-list--inner--author a {
        color: #999999;
        font-weight: bold;
        font-size: 1.5rem; }
    .unformatted-list--inner--title {
      border-top: 1px solid rgba(153, 153, 153, 0.2);
      padding: 19px 0;
      font-size: 9vw;
      font-family: 'Noto Serif', serif;
      border-bottom: 1px solid rgba(153, 153, 153, 0.2);
      margin: 0;
      color: #262626;
      text-align: center; }
      @media all and (min-width: 768px) {
        .unformatted-list--inner--title {
          font-size: 4.5rem;
          padding: 29px 0 32px 58px; } }
      @media all and (min-width: 1024px) {
        .unformatted-list--inner--title {
          margin: 0 0 66px 0;
          text-align: left; } }
    .unformatted-list--inner--content {
      padding: 0 58px 0 58px;
      color: #777; }
  .unformatted-list img {
    margin: 0 auto;
    max-width: 171px;
    width: 100%; }
    @media all and (min-width: 1024px) {
      .unformatted-list img {
        margin: 0 38px 0 0; } }
  .unformatted-list p {
    color: #999999;
    font-size: 0.8rem; }
    @media all and (min-width: 1024px) {
      .unformatted-list p {
        font-size: 1rem; } }
  .unformatted-list a {
    color: #262626;
    font-size: 0.8rem; }
    .unformatted-list a:hover {
      color: #889a6f; }
    @media all and (min-width: 1024px) {
      .unformatted-list a {
        font-size: 1rem; } }
  .unformatted-list .pop-list li a {
    background-color: transparent;
    color: #262626;
    display: block;
    padding: 10px 0px 16px 0;
    height: initial;
    text-transform: none; }
    .unformatted-list .pop-list li a:after {
      content: '';
      width: 30px;
      height: 30px;
      border: 1px solid rgba(153, 153, 153, 0.2);
      display: block;
      float: right; }
    .unformatted-list .pop-list li a.active:after {
      background: url("../images/check.png") center no-repeat; }
    .unformatted-list .pop-list li a:hover {
      color: #889a6f; }
  .unformatted-list .pop-list li {
    margin: 0;
    display: block;
    border-bottom: 1px solid rgba(153, 153, 153, 0.2); }

.pager {
  margin: 15px 0 0 0; }
  .pager ul {
    text-align: center; }
    .pager ul:before, .pager ul:after {
      clear: both;
      content: '';
      display: block; }
    .pager ul li {
      display: inline-block;
      margin: 0 10px; }

.pager__item.is-active a {
  font-weight: bold; }

.bpc-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 100vh;
  width: 100vw;
  text-align: center;
  background: rgba(0, 0, 0, 0.7); }
  .bpc-popup--inner {
    width: 60vw;
    margin: auto;
    height: 85vh;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }
    .bpc-popup--inner--close {
      position: absolute;
      top: 0;
      right: 0;
      text-indent: -999999px;
      background: url("../images/square-close-button-white.png") 0 0 no-repeat;
      width: 25px;
      height: 25px;
      display: block;
      cursor: pointer; }
    .bpc-popup--inner--image {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      width: auto;
      height: inherit;
      background: #fbfbfb;
      padding: 3% 7%; }

/**
 * Bardzo się wstydzę, bo...
 */
.material-link {
  float: right; }
