.system{
	&--popup{
		position: absolute;
		top: 0;
		right: 0;
		width: 250px;
		height: 280px;
		z-index: 9999;
		//background: rgba(0,0,0,0.8);
		&--inner{
			position: absolute;
			left: 0;
			top: 10vh;
			right: 0;
			text-align: center;
			margin: auto;
			
			background: $light-grey;
			
			padding: 2%;
			overflow: auto;
			box-shadow: 0px 0px 10px $dark-grey;
			color: $dark-grey;
			*{
				color: $dark-grey;
				font-size: 14px;
				display: block;
				margin: 10px 0 10px 0;
			}
			h2{
				text-align: center;
				font-size: 18px;
				text-transform: uppercase;
				font-family: 'Noto Serif', serif;
			}
		}
	}
}
.close-popup{
	font-size: 18px;
    position: absolute;
    top: 0;
    right: 3%;
	cursor: pointer;
}

.path-user .login-page{
	max-width: 1366px;
    margin: 0 auto;
    padding: 100px 50px;
	> div {
		display: flex;
		> div:first-child{
			width: 450px;
		}
	}
	li{
		margin: 0 0 15px 0;
		a{
			color: #262626;
			font-size: 14px;
			@media all and (min-width: 1024px){
				font-size: 18px;
			}
			&:hover{
				text-decoration: underline;
			}
		}
	}
	label {
		width: 100%;
		display: block;
		color: #999;
		font-size: 14px;
		text-transform: uppercase;
	}
	input {
		width: 180px;
		font-family: 'Open Sans',sans-serif;
		
		padding: 6px;
		box-shadow: none;
		color: #262626;
		margin: 10px 0;
		border: 1px solid;
		&.form-submit{
			color: #262626;
			font-weight: 700;
			font-size: 18px;
			cursor: pointer;
			background: #fbfbfb;
		}
	}
	#edit-name--description, #edit-pass--description{
		display: none;
	}
}